<template>
  <div>
    <div class="hero is-large parallax" :style="mainImage">
      <div class="wrapForElements">
        <figure >
          <img src="@/assets/logo1.png" />
        </figure>

        <div class="textwrap">
          <span class="textTitle ">
            Вітаю <br />
            на сторінці
            <span style="color: yellow;"
              >першої майстерні <br />
              дитячих свят.
            </span>
            <br />
            <br />
          </span>
          <span class="subtitletext ">
            Веселі аніматори, <br />
            шоу програми, <br />
            майстеркласи та багато іншого.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcometext",
  data() {
    return {
      mainImage: {
        backgroundImage: ` url(${require("@/assets/welcome2.png")})`,
      },
      isLinksView: false,
    };
  },
};
</script>

<style lang="css" scoped>
.hero {
  height: 150vh;
  width: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
@media (max-width: 820px) {
  .hero {
    background-position: right;
    height: 100vh;
  }
}

.parallax {
  /* The image used */

  /* Set a specific height */
  min-height: 160vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
}
@media only screen and (max-device-width: 767px) {
  .parallax {
    background-attachment: scroll;
    min-height: 105vh;
  }
}

.wrapForElements{
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 22px;
  margin-bottom: 10px;
  
}
@media (min-width: 1024px) {
  .wrapForElements{
    font-size: 25px;
    position: absolute;
    left:50%;
    
  
  }
}
@media (max-width: 820px) {
  .wrapForElements{
    font-size: 35px;
  
  
  }
}
@media (max-width: 767px) {
  .wrapForElements{
    font-size: 21px;
    min-height: 110vh;

  
  
  }
}
@media (max-width: 844px){
@media (orientation: landscape){
  .wrapForElements img {
    width: 300px;
  }
.textwrap{
  font-size: 18px;
}

.parallax{
  background-attachment: fixed;

}
}
}


.textwrap{
  background-color: rgba(1, 1, 1, 0.265);
  border-radius: 90px;
  margin: 1rem;
  margin-bottom: 2rem;
  padding:1rem

}







</style>