import {
    v4 as uuidv4
} from 'uuid';
import axios from "axios"
import apiEndPoints from "../../constans/apiEndPoints"

export default {
    namespaced: true,

    state: {
        ReviewsListData: [
            // {
            //     _id: 1,
            //     reviewerName: 'Оля',
            //     textReview: 'Готуємо технологічні суші, сервіруємо блюда з padding-ами мінімум 20рх,саме тому вони виглядають дуже привабливо,а наші страви завжди свіжі завдяки технології Shift + f5.Також зауважимо, ми дотримуємося соціального margin в 1,5 м. та тримаємо нашу console в чистоті, все це for each наших клієнтів В нас можите замовити всі блюда, навіть яких немає на сайті. Наш девіз - Жодних 404 для клієнтів!',
            //     img: require('@/assets/about.png'),
                
            // },
            // {
            //     _id: 2,
            //     reviewerName: 'Толя',
            //     textReview: 'Готуємо технологічні суші, сервіруємо блюда з padding-ами мінімум 20рх,саме тому вони виглядають дуже привабливо,а наші страви завжди свіжі завдяки технології Shift + f5.Також зауважимо, ми дотримуємося соціального margin в 1,5 м. та тримаємо нашу console в чистоті, все це for each наших клієнтів В нас можите замовити всі блюда, навіть яких немає на сайті. Наш девіз - Жодних 404 для клієнтів!',
            //     img: require('@/assets/about.jpeg'),
                
            // },
            // {
            //     _id: 3,
            //     reviewerName: 'Соля',
            //     textReview: 'Готуємо технологічні суші, сервіруємо блюда з padding-ами мінімум 20рх,саме тому вони виглядають дуже привабливо,а наші страви завжди свіжі завдяки технології Shift + f5.Також зауважимо, ми дотримуємося соціального margin в 1,5 м. та тримаємо нашу console в чистоті, все це for each наших клієнтів В нас можите замовити всі блюда, навіть яких немає на сайті. Наш девіз - Жодних 404 для клієнтів!',
            //     img: require('@/assets/party1.jpeg'),
                
            // },
        ]
    },

    mutations: {
        setReviewsListData(state, data) {
            state.ReviewsListData = [...data]
            },
       

    //     updateReviews(state, ReviewsData) {
    //         const reviewIndex = state.ReviewsListData.findIndex(
    //             (review) => review._id == ReviewsData._id)
    //         state.ReviewsListData[reviewIndex] = ReviewsData
    //     },

    //     deleteReviews(state, ReviewsData) {
    //         const RestOfReviewsList = state.ReviewsListData.filter(
    //             (review) => review._id !== ReviewsData._id)
    //         state.ReviewsListData = RestOfReviewsList

    //     }
    },


    actions: {
        loadData({
            commit
          }) {
            axios.get(apiEndPoints.reviews.read)
              .then((res) => res.data)
              .then((resData) => {
                console.log('load data resdata ok');
                if (resData) {
                  commit("setReviewsListData", resData)
                }
              })
          },

          addReview({
            dispatch
         },reviewData) {
      
           axios
             .post(apiEndPoints.reviews.add, reviewData)
             .then(
               //Якщо добре
               (res) => res.data,
      
             )
      
             .then((resData) => {
              console.log('ok');
               if (resData) dispatch('loadData')
               else throw new Error("Fatch failed!");
             })
             .catch((err) => { //Якщо погано
               console.log(` tut eror:  @@@@ ${err}`);
             })
         },

         updateReviewAction({
            dispatch
          }, reviewData) {
            
            axios
              .put(apiEndPoints.reviews.update, reviewData)
              .then(
                //Якщо добре
                (res) => res.data,
              )
              .then((resData) => {
                console.log(`resdata  $$$ ${resData}`);
                if (resData) dispatch('loadData')
                else throw new Error("Fatch failed!");
              })
          },

          deleteReviewAction({ dispatch }, _id) {
      
            console.log("id");
            console.log(_id);
            // axios.delete(apiEndpoints.products.delete,{params:{id}})    //через params, тоді на сервері берем з req.query.id
            axios.delete(apiEndPoints.reviews.delete, { data: { _id } }) //через data, тоді на сервері берем з req.body.id        
              .then(                             //Якщо добре
                res => res.data
              )
              .then((resData) => {
                if(resData.success)   dispatch ('loadData')
                else throw new Error('Fatch failed!')
              })
              
          },
    },

    getters: {
        getReviewsListData: (state) => state.ReviewsListData,

        getReviewsById: (state) => (id) => {
            return state.ReviewsListData.find((item) => item._id == id)
        }

    }
}