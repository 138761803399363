<template>
  <div>
    <div class="subtitle">
      Заповніть форму замовлення, і я неодмінно зв'яжуся з Вами! <br />
      А якщо лінь заповнювати, тоді телефонуйте
      <a href="tel: +380994380991"> (099)438 09 91 </a> <br />
      Або тисніть на значок вайберу
      <a title="Viber" href="viber://chat?number=+380994380991">
        <img src="@/assets/viber_logo.png" alt="viber" />
      </a>
    </div>
   
    <div class="orderForm has-text-centered">
      <b-field label="* Ім'я:">
        <b-input
          placeholder="Як Вас звати?"
          required
          v-model="userName"
        ></b-input>
      </b-field>

      <b-field label="* Телефон:">
        <b-input
          required
          v-model="userTel"
          type="number"
          name="phone"
          placeholder="0994380991"
          title="введіть номер"
          minlength="10"
          maxlength="13"
        >
        </b-input>
      </b-field>

      <b-field label="Email:">
        <b-input
          
          v-model="userEmail"
          type="email"
          placeholder="mickeymouse@waltdisney.com"
        ></b-input>
      </b-field>

      <b-field label="* Де святкуємо?">
        <b-input
          placeholder="Наприклад: 
             м.Ужгород вул. Волошина, в кафе 'Зелена черепаха'"
          required
          v-model="partyArea"
        ></b-input>
      </b-field>

      <b-field label="Яка кількість дітей ?">
        <b-numberinput
          type="is-success"
          v-model="childrenCount"
          min="1"
          max="35"
          controls-position="compact"
        ></b-numberinput>
      </b-field>

      <b-field label="На коли плануємо ?">
        <b-datetimepicker
          v-model="partyTime"
          placeholder="Click to select..."
          :min-datetime="minDatetime"
          :max-datetime="maxDatetime"
        >
        </b-datetimepicker>
      </b-field>
      <div v-if="isCustomDuration">
        <b-field label=" Скільки годин триватиме ?">
          <b-numberinput
            v-model="hoursPlaying"
            min="1"
            max="6"
            controls-position="compact"
          ></b-numberinput>
        </b-field>
        * мінімум одна година
      </div>
      <b-field label="Побажання до замовлення: ">
        <b-input
          placeholder="Наприклад: 
              Привіт!  Іменинника звати Женя, він обожнює людину-павука, 
              Хочемо щоб свято було проведено на цю тематику.
              Дякую! "
          v-model="userCommentsToOrder"
          maxlength="200"
          type="textarea"
        ></b-input>
      </b-field>

      <b-button
        class="showQuickOrderButton pulseQuickOrderButton"
        v-if="userName && userTel"
        @click="MakeOrder(),alertCustom()"
        type="is-success"
        >Замовляю
      </b-button>
      <b-button disabled v-if="!userName || !userTel" type="is-light">
        Замовляю
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrderForm",
  computed: {
    ...mapGetters("servises", ["getServisById"]),
    servisId() {
      return this.$route.params.servis_id;///
    },

    servisName() {
      let servis = this.getServisById(this.servisId);
      return servis.title;
      
    },

    isCustomDuration() {
      let servis = this.getServisById(this.servisId);
      return servis.isCustomDuration;
    },
  },

  data() {
    const min = new Date();
    min.setDate(min.getDate() + 1);
    min.setHours(9);
    min.setMinutes(0);
    min.setSeconds(0);
    const max = new Date();
    max.setDate(max.getDate() + 90);
    max.setHours(18);
    max.setMinutes(0);
    max.setSeconds(0);
    const pickedDate = new Date();
    pickedDate.setHours(18);
    pickedDate.setMinutes(0);
    pickedDate.setSeconds(0);

    return {
      userName: "",
      userTel: "",
      userEmail: "",
      partyArea: "",
      childrenCount: 1,
      userCommentsToOrder: "",
      partyTime: pickedDate,
      hoursPlaying: 1,
      minDatetime: min,
      maxDatetime: max,
      DateOfOrder: new Date().toLocaleString(),
    };
  },

  methods: {
    ...mapActions("orders", ["addOrder"]),

    MakeOrder() {
      this.addOrder({
        userName: this.userName,
        userTel: this.userTel,
        userEmail: this.userEmail,
        partyArea: this.partyArea,
        childrenCount: this.childrenCount,
        userCommentsToOrder: this.userCommentsToOrder,
        partyTime: this.partyTime.toLocaleString(),
        hoursPlaying: this.hoursPlaying,
        OrderedServisName: this.servisName,
        servisId: this.servisId,
        DateOfOrder: this.DateOfOrder
      });
      

    },
   
     alertCustom() {
      this.$buefy.dialog.alert({
        title: "Замовлення зроблено",
        message: "Дякую за замовлення, скоро Вам зателефоную  <b>Гарного дня!</b>!",
        confirmText: "Окей",
      });
       this.$router.push({
        name: "Home",
      });
    },
  },
   
};
</script>

<style lang="css" scoped>

@media only screen and (min-device-width: 1024px) {
.orderForm {
  margin: auto;
  text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
  max-width: 420px;
  padding: 1rem;
  background-image: linear-gradient(90deg, #f7b7f9, #ecc6f4);
  border-radius: 30px;
  z-index: 299;
  animation: myAnim 3s ease 0s 1 normal forwards;
}
@keyframes myAnim {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

}
.orderForm {
  margin: auto;
  text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
  max-width: 420px;
  padding: 1rem;
  background-image: linear-gradient(90deg, #f7b7f9, #ecc6f4);
  border-radius: 30px;
  z-index: 299;
}




.subtitle {
  margin-top: 2rem;
  text-align: center;
     padding: 30px;
    background-repeat: no-repeat;
    margin-bottom: 1rem;
    background-image: linear-gradient(90deg, rgb(214, 243, 243), rgb(190, 248, 223));
}

.showQuickOrderButton {
  opacity: 1;
  transition: 2s;
  animation: show 2s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5ms;
  animation: pulse 3s;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(143, 247, 139, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
  }
  50% {
    -moz-box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
    box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
  }
  100% {
    -moz-box-shadow: 0 0 0 rgba(143, 247, 139, 0.5);
    box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
  }
}



</style>