<template>
  <div>
    <navbar />
    <div class="mainDiv">
      <h2 class="title has-text-centered">{{ AddOrEditTitle }}</h2>

      <b-field label="Заголовок">
        <b-input v-model="title"></b-input>
      </b-field>

      <b-field label="Текст новини">
        <b-input
          placeholder="Наприклад: 
              Хороша Новина "
          v-model="text"
          type="textarea"
          maxlength="500"
        ></b-input>
      </b-field>

      <b-field label="Дата публікації (зараз вказана поточна  дата та час)">
        <b-input v-model="DateOfNews"></b-input>
      </b-field>

      <div>
        <b-field class="file is-warning" :class="{ 'has-name': !!file }">
          <b-upload v-model="file" class="file-label" @input="loadImage">
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Додати фото </span>
            </span>
            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-upload>
        </b-field>
        <div v-if="img">
          <img :src="img" alt="" width="100" />
        </div>
      </div>

      <b-button
        @click="
          onAdd();
          jumpToNewsPage();
        "
        type="is-success"
        outlined
        >{{ buttonTitle }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "@/components/Navbar";
export default {
  name: "AddNewsForm",
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters("news", ["getNewsById"]),
    newsId() {
      if (this.$route.params.news_id === "new") return null;
      else return this.$route.params.news_id;
    },

    buttonTitle() {
      if (this.newsId) return "Зберегти зміни";
      else return " Додати новину";
    },
    AddOrEditTitle() {
      if (this.newsId) return "Редагувати новину";
      else return "Додати  новину";
    },
  },
  data() {
    return {
      title: null,
      img: null,
      text: null,
      DateOfNews: new Date().toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}),
      file:null,
    };
  },

  methods: {
    ...mapActions("news", ["addNews", "updateNewsAction"]),

     readFile(file) {
      // return new Promise((resolve, reject) => {
      //   let myReader = new FileReader();
      //   myReader.onloadend = function (e) {
      //     resolve(myReader.result);
      //     reject(console.log(`err is ${e}`));
      //   };
      //   myReader.readAsDataURL(file);
      // });
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(console.log(` error ${error}`));

        
      });
    },
     async loadImage(file) {
      this.img = await this.readFile(file);
    },

    onAdd() {
      if (!this.newsId) {
        this.addNews({
          title: this.title,
          img: this.img,
          text: this.text,
          DateOfNews: this.DateOfNews,
        });
      } else {
        this.updateNewsAction({
          _id: this.newsId,
          title: this.title,
          img: this.img,
          text: this.text,
          DateOfNews: this.DateOfNews,
        });
      }
    },
    jumpToNewsPage() {
      this.$router.push({
        name: "NewsPage",
      });
    },
  },

  mounted() {
    console.log(this.newsId);
    if (this.newsId) {
      var news = this.getNewsById(this.newsId);
      this.title = news.title;
      this.img = news.img;
      this.text = news.text;
      this.DateOfNews = news.DateOfNews;
    }
  },
};
</script>

<style lang="css" scoped>
.mainDiv {
  max-width: 500px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: auto;
}
</style>