<template>
  <div class="wrap">
    <div class="card">
      <span>
        <img v-if="img"
          class="image"
          v-lazyload
          :data-src="img"
          :src="require('@/assets/loading.gif')"
          alt="Image"
        />
      </span>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <span class="title is-4 ">{{ title }}</span>
            <br />
            <br />
            <small class="Date-stamp has-text-left">{{ DateOfNews }}</small>
          </div>
        </div>

        <div class="content">
          {{ text }}
        </div>

        <br />
      </div>
    </div>
  </div>
</template>

<script>
import lazyload from "../../../directives/lazyload";
export default {
  name: "NewsItem",

  props: {
    _id: {
      type: [String, Number],
      default: null,
    },
    img: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    DateOfNews: {
      type: [Date, String],
      default: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    },
  },

  directives: {
    lazyload,
  },
};
</script>


<style lang="css" scoped>
.wrap {
  display: flex;
  justify-content: center;

}
.card{
  margin: 10px;
}

@media only screen and (min-device-width: 1024px) {
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 15px;
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(150, 126, 126, 1.5);
  max-width: 1000px;
}
.image {
  border-radius: 30px;
  width: 270px;

  margin: 10px;
}

.card-content {
  width: 550px;

  
}
}

.Date-stamp {
  text-decoration: underline;
  color: rgb(93, 175, 247);
  margin: 2rem;
}
</style>