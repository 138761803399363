<template>
  <div>
    <navbar />

    <div
      v-for="news in getNewsListData"
      :key="news._id"
      class="box has-text-centered"
    >
      <article class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <img :src="news.img" alt="Image" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <p>
              <small class="Date-stamp">{{ news.DateOfNews }}</small>
              <br />
            </p>

            <hr />
            <strong>{{ news.title }}</strong>
            <br>
            <b-button @click="onEdit(news._id)" type="is-primary">
              Редагувати
            </b-button>
            <b-button
              @click="confirmCustomDelete(news)"
              type="is-danger "
            >
              Видалити новину
            </b-button>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EditNewsPage",

  components: {
    Navbar,
  },

  computed: {
    ...mapGetters("news", ["getNewsListData"]),
  },

  methods: {
    ...mapActions("news", ["deleteNewsAction","loadData"]),

    onEdit(_id) {
      this.$router.push({
        name: "UpdateNewsForm",
        params: { news_id: _id },
      });
    },

    confirmCustomDelete(news) {
      this.$buefy.dialog.confirm({
        title: "Видалення новини",
        message:
          "Ти точно хочеш <b>видалити</b> цю новину? Цю дію потім неможливо буде відмінити! ",
        confirmText: "Видалити",
        cancelText: "Не треба видаляти",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$buefy.toast.open("Новину видалено!") &&
          this.deleteNewsAction(news),
      });
    },
  },

  mounted () {
    this.loadData(this);
  },
};
</script>

<style lang="css" scoped>
.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}

@media only screen and (max-device-width: 768px) {
  .column {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.card {
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  margin: 2rem;
  text-align: center;
  box-shadow: 0 0 10px rgba(150, 126, 126, 0.5);
  border-radius: 10px;
  width: 250px;
  padding: 1.5rem;
  margin: 1rem;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card button {
  margin: 10px;
}

.box {
  margin: 1rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.Date-stamp{
  text-decoration:underline;
  color:rgb(93, 175, 247)
}
.box button {
  margin: 10px;
  
}
</style>