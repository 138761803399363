export default {
    servises: {
      read: `${process.env.VUE_APP_API_URL}/servises`,
      findById: `${process.env.VUE_APP_API_URL}/servises/:servis_id`,///
      add: `${process.env.VUE_APP_API_URL}/servises/add`,
      update: `${process.env.VUE_APP_API_URL}/servises/update`,
      delete: `${process.env.VUE_APP_API_URL}/servises/delete`,
    },
    news: {
      read: `${process.env.VUE_APP_API_URL}/news`,
      add: `${process.env.VUE_APP_API_URL}/news/add`,
      update: `${process.env.VUE_APP_API_URL}/news/update`,
      delete: `${process.env.VUE_APP_API_URL}/news/delete`,
    },
    reviews: {
      read: `${process.env.VUE_APP_API_URL}/reviews`,
      add: `${process.env.VUE_APP_API_URL}/reviews/add`,
      update: `${process.env.VUE_APP_API_URL}/reviews/update`,
      delete: `${process.env.VUE_APP_API_URL}/reviews/delete`,
    },
    page: {
      read: `${process.env.VUE_APP_API_URL}/page`,
      // add: `${process.env.VUE_APP_API_URL}/page/add`,
      update: `${process.env.VUE_APP_API_URL}/page/update`,
      // delete: `${process.env.VUE_APP_API_URL}/page/delete`,
    },
    orders: {
      read: `${process.env.VUE_APP_API_URL}/orders`,
      add: `${process.env.VUE_APP_API_URL}/orders/add`,
      update: `${process.env.VUE_APP_API_URL}/orders/update`,
      delete: `${process.env.VUE_APP_API_URL}/orders/delete`,
    },
    user: {
      signup:`${process.env.VUE_APP_API_URL}/users/signup`,
      login:`${process.env.VUE_APP_API_URL}/users/login`
  },
    

  };