<template>
  <div >
    <navbar />
    <section class="hero is-small ">
      <div class="hero-body has-text-centered">
        <p class="title ">Галерея</p>
        <p class="subtitle">Тут зібрано кілька світлин з наших свят ⭐️</p>
      </div>
    </section>

    <div class="wrap">
      <b-carousel
        class="carusel"
        :autoplay="true"
        :pause-hover="false"
        with-carousel-list
        :indicator="false"
      >
        <b-carousel-item v-for="(item, i) in items" :key="i">
          <figure class="image">
            <img class="image" :src="item.image" />
          </figure>
        </b-carousel-item>

        <template #list="props">
          <b-carousel-list 
            v-model="props.active"
            :data="items"
            :repeat="true"
            v-bind="al"
            @switch="props.switch($event, false)"
            as-indicator
          />
        </template>
        <template #overlay>
          <div class="has-text-centered has-text-white">Hello i'am overlay</div>
        </template>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: "Gallery",
  components: {
    Navbar,
  },
  data() {
    return {
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 12,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 5,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 3,
          },
        },
      },
      items: [
        {
          title: "Slide 1",
          image: require("@/assets/galery.jpeg"),
        },
        {
          title: "Slide 2",
          image: require("@/assets/galery2.jpeg"),
        },
        {
          title: "Slide 3",
          image: require("@/assets/galery3.jpeg"),
        },
        {
          title: "Slide 4",
          image: require("@/assets/galery4.jpeg"),
        },
        {
          title: "Slide 5",
          image: require("@/assets/galery5.jpeg"),
        },
        {
          title: "Slide 6",
          image: require("@/assets/galery6.jpeg"),
        },
         {
          title: "Slide 7",
          image: require("@/assets/galery7.jpeg"),
        },
         {
          title: "Slide 8",
          image: require("@/assets/galery8.jpeg"),
        },
         {
          title: "Slide 9",
          image: require("@/assets/galery9.jpeg"),
        },
         {
          title: "Slide 10",
          image: require("@/assets/galery10.jpeg"),
        },
         {
          title: "Slide 11",
          image: require("@/assets/galery11.jpeg"),
        },
         {
          title: "Slide 12",
          image: require("@/assets/galery12.jpeg"),
        },
      ],
    };
  },
  methods: {
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        document.documentElement.classList.add("is-clipped");
      } else {
        document.documentElement.classList.remove("is-clipped");
      }
    },
  },
};
</script>

<style lang="css" scoped>
@media only screen and (min-device-width: 1024px) {
  .wrap{
    /* position: relative; */
  
    background-color: rgba(12, 12, 11, 0.708);
    
  
  }

.hero {
    background-color: rgba(12, 12, 11, 0.708);
}
.title  {
  color:rgb(255, 255, 255);
}
.subtitle{
    color:rgb(255, 255, 255);
}

  .carousel {
 
    border-radius: 30px;
    margin: auto;
    padding-bottom: 90px;
    width: 750px;
    height: auto;
  }



}



</style>