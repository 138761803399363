<template>
  <div>
    <navbar />
    <!-- <b-button @click="sort()"> sort</b-button> -->
    <div class="column">
      <div v-for="servis in sortedGetServisListData" :key="servis._id">
        <div class="card">
          <div class="ribbon" v-if="servis.hasRibbon">
            <span
              v-bind:class="{
                'ribbon-span': servis.ribbonStyle1,
                'ribbon2-span': servis.ribbonStyle2,
              }"
              >{{ servis.textOnRibbon }}</span
            >
          </div>

          <div class="card-conten">
            <div class="image is-16by9">
              <img :src="servis.img" alt="servis.title" />
            </div>
            <br />
            <div class="content">
              <p class="title is-6">{{ servis.title }}</p>
              <br />
              <p class="subtitle is-6" v-if="servis.isPriceVisible">
                ціна : {{ servis.price }} грн.
              </p>
              <p class="subtitle is-6" v-if="!servis.isPriceVisible">
                ціна не відображ.
              </p>
              <p class="subtitle is-6">рейтинг {{ servis.rating }}</p>

              <b-button @click="onEdit(servis._id)" type="is-primary is-light">
                Редагувати
              </b-button>

              <b-button
                @click="confirmCustomDelete(servis._id)"
                type="is-primary is-danger"
              >
                Видалити послугу
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EditServisForm",

  components: {
    Navbar,
  },

  computed: {
    ...mapGetters("servises", ["getServisListData", ]),

    sortedGetServisListData() {
      let sortedArray = this.getServisListData;
      return sortedArray.sort((a, b) => a.rating - b.rating);
    },
  },

  methods: {
    ...mapActions("servises", ["deleteServisAction", "loadData"]),

    onEdit(_id) {
      this.$router.push({
        name: "UpdateServisForm",
        params: { servis_id: _id },
      });
    },

    confirmCustomDelete(_id) {
      this.$buefy.dialog.confirm({
        title: "Видалення послуги",
        message:
          "Ти точно хочеш <b>видалити</b> цю послугу? Цю дію потім неможливо буде відмінити! ",
        confirmText: "Видалити",
        cancelText: "Не треба видаляти",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$buefy.toast.open("Послугу видалено з бази!") &&
          this.deleteServisAction(_id) &&
          this.loadData(),
      });
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style lang="css" scoped>
.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}

@media only screen and (max-device-width: 768px) {
  .column {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.card {
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  margin: 2rem;
  text-align: center;
  box-shadow: 0 0 10px rgba(150, 126, 126, 0.5);
  border-radius: 10px;
  width: 250px;
  padding: 1.5rem;
  margin: 1rem;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card button {
  margin: 10px;
}
.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
</style>