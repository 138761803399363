<template>
  <div >
    <router-view> </router-view>
     
  </div>
</template>

<script>


export default {
  name: 'App'
  
}
</script>

<style>
body{
  font-family: 'Comfortaa', cursive;
  


}

.ribbon-span {
  font-size: 14px;
   font-family:sans-serif;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #e6e6e6;
  background: linear-gradient(#C90273 0%, #EB1757 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; left: -21px;
}
.ribbon2-span {
  font-size: 14px;
  font-family:sans-serif;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #e6e6e6;
  background: linear-gradient(#58cfe9 0%, #3b84f2 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; left: -21px;
}
</style>
