import apiEndPoints from "../../constans/apiEndPoints";
import axios from "axios"

// import { v4 as uuidv4 } from 'uuid';
export default {
    namespaced: true,

    state: {
        OrderList: [
            
            // {
            //     _id: 1,
            //     userName: "Test User  Name is : test",
            //     userTel: "12312",
            //     userEmail: "123",
            //     partyArea: "123",
            //     childrenCount: 1,
            //     userCommentsToOrder: "123",
            //     partyTime: "123",
            //     hoursPlaying: 1,
            //     servisId: 123,
            //     OrderedServisName: '123',
            //     DateOfOrder: "now"

            // },
            
        ],
    },

    mutations: {

        setOrderListData(state, data) {
            state.OrderList = [...data]
            },

        // setOrderList(state, data) {
        //     state.orderList.unshift({
        //         _id: uuidv4(),
        //         ...data
        //     })
        // }
    },

    actions: {

        loadData({
            commit
          }) {
            axios.get(apiEndPoints.orders.read)
              .then((res) => res.data)
              .then((resData) => {
                console.log('load data orders resdata ok');
                if (resData) {
                  commit("setOrderListData", resData)
                }
              })
          },

          addOrder({
            dispatch
         },orderData) {
      
           axios
             .post(apiEndPoints.orders.add, orderData)
             .then(
               //Якщо добре
               (res) => res.data,
      
             )
      
             .then((resData) => {
              console.log('ok orders');
               if (resData) dispatch('loadData')
               else throw new Error("Fatch failed!");
             })
             .catch((err) => { //Якщо погано
               console.log(` tut eror orders:  @@@@ ${err}`);
             })
         },

         updateOrderAction({
            dispatch
          }, orderData) {
            
            axios
              .put(apiEndPoints.orders.update, orderData)
              .then(
                //Якщо добре
                (res) => res.data,
              )
              .then((resData) => {
                console.log(`resdata  $$$ ${resData}`);
                if (resData) dispatch('loadData')
                else throw new Error("Fatch failed!");
              })
          },

          deleteOrderAction({ dispatch }, _id) {
      
            console.log("id");
            console.log(_id);
            // axios.delete(apiEndpoints.products.delete,{params:{id}})    //через params, тоді на сервері берем з req.query.id
            axios.delete(apiEndPoints.orders.delete, { data: { _id } }) //через data, тоді на сервері берем з req.body.id        
              .then(                             //Якщо добре
                res => res.data
              )
              .then((resData) => {
                if(resData.success)   dispatch ('loadData')
                else throw new Error('Fatch failed!')
              })
              
          },



        // addOrder({
        //     commit
        // }, orderData) {
        //     commit('setOrderList', orderData)
        // },
    },

    getters: {
        getOrderList: (state) => state.OrderList,

        getOrderById: (state) => (id) => {
            return state.OrderList.find((item) => item._id == id)
        }

    }
}