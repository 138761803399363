
        <template>
  <b-navbar  class="is-dark wrap">
    <template  #start >
    
      <b-navbar-item class="colorLink1 linkHover " tag="router-link" :to="{ path: '/' }">
        Головна</b-navbar-item
      >
      <b-navbar-item class="colorLink2 linkHover" tag="router-link" :to="{ path: '/about' }">
        Про Нас
      </b-navbar-item>
      <b-navbar-item class="colorLink3 linkHover" tag="router-link" :to="{ path: '/gallery' }">
        Галерея
      </b-navbar-item>
      <b-navbar-item class="colorLink4 linkHover" tag="router-link" :to="{ path: '/news' }">
        Новини
      </b-navbar-item>
    
      <b-navbar-item  class="colorLink5 linkHover" tag="router-link" :to="{ path: '/servis-page' }">
        Послуги
      </b-navbar-item>
        <b-navbar-item v-if="isAuthenticated()" class="linkHover" tag="router-link" :to="{ path: '/signup' }">
        + 
      </b-navbar-item>
      <!-- <b-navbar-item  v-if="!isAuthenticated()" tag="router-link" :to="{ path: '/login' }">
        Увійти 
      </b-navbar-item> -->
     
      <b-navbar-dropdown v-if="isAuthenticated()" class="dropDown linkHover " label="адмін">
        <b-navbar-item v-if="userNick" class="adminName">
         Привіт {{ userNick }} !
      </b-navbar-item>
 
       
        <b-navbar-item   class="linkHover"  tag="router-link" :to="{ path: '/edit-servis-form' }">
          Редагувати послуги</b-navbar-item
        >
        <b-navbar-item class="linkHover"  tag="router-link" :to="{ path: '/add-servis/new' }">
          Додати послугу
        </b-navbar-item>
        <b-navbar-item class="linkHover" tag="router-link" :to="{ path: '/add-news/new' }">
          Додати новину
        </b-navbar-item>
        <b-navbar-item class="linkHover" tag="router-link" :to="{ path: '/edit-news' }">
          Редагувати новину</b-navbar-item
        >
        <b-navbar-item class="linkHover" tag="router-link" :to="{ path: '/add-reviews/new' }">
          Додати відгук
        </b-navbar-item>
        <b-navbar-item class="linkHover" tag="router-link" :to="{ path: '/edit-reviews' }">
          Редагувати відгук</b-navbar-item
        >
        <b-navbar-item class="linkHover" tag="router-link" :to="{ path: '/order-list' }">
          Список замовлень</b-navbar-item
        >
      </b-navbar-dropdown>
       <b-navbar-item v-if="isAuthenticated()" >
        <b-button style="color:white" @click="toLogout()"> Вийти </b-button>
      </b-navbar-item>
    </template>

    <template #end >
      <b-navbar-item tag="div" >
        <b-navbar-item href="tel: +380994380991" class="tel-number">
          (099)438 09 91
        </b-navbar-item>

        <a title="Viber" href="viber://chat?number=+380994380991">
          <img src="@/assets/viber_logo.png" alt="viber"
        /></a>
        <a
          target="blank"
          title="instagram"
          href="https://www.instagram.com/show_time_uzh/"
        >
          <img src="@/assets/instagram.png" alt="instagram"
        /></a>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>



<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Navbar",

  computed: {
    ...mapGetters("auth", ["isAuthenticated", "userNick"]),
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    toLogout() {
      this.logout;
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="css" scoped>




.tel-number {
  color: rgb(255, 238, 7) !important;
}
  /* .navbar-menu{
      background-color: #000000 !important;
  } */
.colorLink1 {
  color: #edf356 !important;
}
.colorLink2 {
  color: #ff77f6 !important;
}
.colorLink3 {
  color: #7bdbf5 !important;
}
.colorLink4 {
  color: #f58bca !important;
}
.colorLink5 {
  color: #ffffff !important;

}

.is-dark  {
  background-color: #000000 !important;
    font-weight: bold;
  
}

.is-dark * {
  background-color: #000000 !important;
  
}
.linkHover:hover {
  background-color: #5b2e50 !important;
  
}
@media (max-width: 767px){
  *{
    font-size: 20px;
    text-align: center;
  }
  
}
.dropDown * {

    color: #ffffff
  }
.dropDown *:hover{

    color: #ffffff !important;
  }
.adminName{
  font-weight: bold;
  color:#edf356 !important;
}
</style>