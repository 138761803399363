import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "./components/Home";
import About from './pages/About'
import Gallery from './pages/Gallery'
import CurrentServis from './pages/CurrentServis'
import EditServisForm from './pages/EditServisForm'
import AddServisForm from  './pages/AddServisForm'
import OrderList from './pages/OrderList'
import NewsPage from './pages/NewsPage'
import AddNewsForm from './pages/AddNewsForm'
import EditNewsPage from './pages/EditNewsPage'
import ServisPage from './pages/ServisPage'
import AddReviewsForm from './pages/AddReviewsForm'
import EditReviewsPage from './pages/EditReviewsPage'
import Signup from './pages/Signup'
import Login from './pages/Login'
import Reviews from './components/Reviews'
import store from'./store';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
        
    },
    {
        path: "/about",
        name: "About",
        component: About
        
    },
 
    {
        path: "/gallery",
        name: "Gallery",
        component: Gallery
        
    },
    {
        path: "/reviews",
        name: "Reviews",
        component: Reviews
        
    },
    {
        path: "/current-servis/:servis_id",//////
        name: "CurrentServis",
        component: CurrentServis
        
    },
    {
        path: "/edit-servis-form",
        name: "EditServisForm",
        component: EditServisForm
        
    },
    {
        path: "/add-servis/new",
        name: "AddServisForm",
        component: AddServisForm
        
    },
    {
        path: "/add-servis/:servis_id",
        name: "UpdateServisForm",
        component: AddServisForm
        
    },
    {
        path: "/order-list",
        name: "OrderList",
        component: OrderList
        
    },
    {
        path: "/news",
        name: "NewsPage",
        component: NewsPage
        
    },
    {
        path: "/add-news/new",
        name: "AddNewsForm",
        component: AddNewsForm
        
    },
    {
        path: "/add-news/:news_id",
        name: "UpdateNewsForm",
        component: AddNewsForm
        
    },
    {
        path: "/edit-news",
        name: "EditNewsPage",
        component: EditNewsPage
        
    },
    {
        path: "/servis-page",
        name: "ServisPage",
        component: ServisPage
        
    },
    {
        path: "/add-reviews/new",
        name: "AddReviewsForm",
        component: AddReviewsForm
        
    },
    {
        path: "/add-reviews/:reviews_id",
        name: "UpdateReviewsForm",
        component: AddReviewsForm
        
    },
    {
        path: "/edit-reviews",
        name: "EditReviewsPage",
        component: EditReviewsPage
        
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup
    },
    {
        path: "/login",
        name: "Login",
        component: Login
    }


]
const router = new VueRouter (
    {
        mode: "history",
        routes
    }
)

router.beforeEach((to, from, next) => {
    let check =
      !store.getters["auth/isAuthenticated"]() &&
      !["Home","About","Gallery","CurrentServis","NewsPage","Login","ServisPage", "Reviews"].includes(to.name);
  
    if (check) {
      // Недопускаємо до захищених роутів, якщо немає токена
  
      next("/");
      return;
    } else {
      next();
    }
  });
export default router