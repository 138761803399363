<template>
  <div class="wrapperDiv">
    <div class="nav-bar">
      <navbar />
    </div>
    

    <div v-for="page in getPageData" :key="page.name">
      <section class="hero is-large parallax herotext" :style="mainImage">
        <div class="textwrap">
          <div class="textTitle">
            {{ page.pageTitle }}
            <br />
          </div>

          <div class="subtitletext">
            {{ page.MainDescriptionPage }}
          </div>
        </div>
      </section>

      <hr />
      <div>
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">СТВОРЮЄМО ДИТЯЧІ СВЯТА </p>
              <p class="title deco-title">{{ yearsExpCounter }} <i class="fa-solid fa-cake-candles"></i></p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ page.subtitle2 }}</p>
              <p class="title deco-title">{{ page.subtitle2Description }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ page.subtitle3 }}</p>
              <p class="title deco-title">{{ page.subtitle3Description }}</p>
            </div>
          </div>
         
        
        </nav>
      </div>
      <hr />

      <div class="tile ">
        <div class="tile is-4 is-vertical is-parent">
          <div class="tile is-child box">
            <p class="title">{{ page.card1Title }}</p>
            <p>
              {{ page.card1Description }}
            </p>
          </div>
          <div class="tile is-child box">
            <p class="title deco-title">{{ page.card2Title }}</p>
            <p>
              {{ page.card2Description }}
            </p>
          </div>
        </div>

        <div class="tile is-parent">
          <div class="tile is-child box">
            <p class="title">{{ page.card3Title }}</p>

            <p>
              {{ page.card3Description }}
            </p>

            <br />
            <b-image :src="page.imageCard3" ratio="2by1"></b-image>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="EditPageFormContainer" v-if="isButtonChangePressed">
      <edit-page-form />
    </div>
    <b-button
      v-if=" isAuthenticated()"
      @click="OnChangePageInformation()"
      type="is-light"
    >
      редагувати сторінку
    </b-button>
    <div class="reviews">
          <reviews />
    </div>


    <my-footer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "@/components/Navbar";
import EditPageForm from "@/components/EditPageForm";
import MyFooter from "@/components/MyFooter";
import Reviews from "@/components/Reviews";
export default {
  name: "About",
  components: {
    Navbar,
    EditPageForm,
    MyFooter,
    Reviews,
  },
  computed: {
    ...mapGetters("pages", ["getPageData"]),
    ...mapGetters("auth", [ "isAuthenticated"]),
    yearsExpCounter() {
      let current = new Date(); // сегодня
      let old = new Date("2017-07-02"); // !!! год-месяц-день !!!

      // сначала находим количество дней между датами
      let days = Math.ceil(
        Math.abs(old.getTime() - current.getTime()) / (1000 * 3600 * 24)
      );
      let year = Math.floor(days / 365); // вычисляем кол-во лет. Math.floor убирает остаток.
      // var months = Math.floor((days - (year * 365)) / 30);
      return year + " " + "років";
    },
  },
  data() {
    return {
      isButtonChangePressed: false,
    
      mainImage: {
        backgroundImage: `linear-gradient(90deg, #2223245f, #17181848), url(${require("@/assets/aboutpage.png")})`,
      },
    };
  },
  methods: {
    ...mapActions("pages", ["loadData"]),
    OnChangePageInformation() {
      if (this.isAuthenticated()) {
        this.isButtonChangePressed = true;
      
        
      }
    },
   
  },

  mounted() {
    this.loadData(this);
  },
};
</script>

<style lang="css" scoped>
.wrapperDiv{
  background-color: rgb(249, 239, 248);
}
.herotext {
  color: white !important;
  text-shadow: 10px 3px 20px #ffffff, 0 30px 70px #ffffff;
}

.textTitle {
  font-size: 70px;
  margin-left: 20%;
  text-align: center;
}
.subtitletext {
  font-size: 30px !important;
  margin-left: 20%;
  text-align: center;
}
@media (max-width: 820px){
  .textTitle {
  font-size: 55px;
  margin-left: 20%;
  text-align: center;
}
.subtitletext {
  font-size: 30px !important;
  margin-left: 20%;
  text-align: center;
}
}

.textwrap {
  position: absolute;
  bottom: 190px;
}

.heading{
  font-size: 15px
}
.carousel {
  max-width: 1160;
  height: 554;
  text-align: center;
}
.reviews{
  color:white !important;
  background-color: #663785 !important;
  padding: 30px;
}
.nav-bar {
  position: sticky;
  top: 0;
  z-index: 1;
}
button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.EditPageFormContainer {
  margin: 10px;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

.parallax {
  height: 100vh;
  min-height: 650px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-device-width: 820px) {
  .parallax {
    min-height: 590px;
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>