<template>
  <div class="wrap">
    <!-- <span
      v-if="!isHappyBirthdayOrderFormButtonClicked"
      class="verticalLine"
    ></span> -->
    <div
      :style="image"
      class="party-background-div has-text-centered"
      @="ClickHappyBirthdayOrderButton()"
    >
      
        <p class="title is-2 subtitle1 textwrap ">
          Незабутній
          <br />
          День Народження
        </p>
        <p class="subtitle subtitle2 is-4">Це буде справжнє свято для дитини</p>
   

      <figure
        class="tizer-unicorn"
        v-if="!isHappyBirthdayOrderFormButtonClicked"
      >
        <img src="@/assets/pngs/16.png" />
      </figure>
      <b-button
        v-if="!isHappyBirthdayOrderFormButtonClicked"
        type="is-success is-large rainbow-5"
        @click="ClickHappyBirthdayOrderButton()"
      >
        Святкувати
        <!-- <a href="#to-quick-order-form">Святкувати</a> -->
      </b-button>

      <div
        class="wrap-all-you-need-for-party-title happyBD-form"
        v-if="isHappyBirthdayOrderFormButtonClicked"
        v-on-clickaway="ClickHappyBirthdayOrderButton"
      >
        <quick-order-form />
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import QuickOrderForm from "@/components/QuickOrderForm";

export default {
  name: "Tizer2",
  directives: {
    onClickaway: onClickaway,
  },

  components: {
    QuickOrderForm,
  },
  data() {
    return {
      image: {
        backgroundImage: `url(${require("@/assets/HappyBDbg.png")})`,
      },
      isHappyBirthdayOrderFormButtonClicked: false,
    };
  },
  methods: {
    ClickHappyBirthdayOrderButton() {
      this.isHappyBirthdayOrderFormButtonClicked =
        !this.isHappyBirthdayOrderFormButtonClicked;
    },
  },
};
</script>

<style lang="css" scoped>
.wrap {
  overflow: hidden;
  position: relative;
}
.verticalLine {
  border-left: 6px dotted #f4efdb;

  height: 100%;
  position: absolute;
  left: 50%;
}

@media (max-width: 820px) {
  .verticalLine {
    display: none;
  }
}

.party-background-div {
  width: 100%;
  min-height: 900px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 1rem;
  height: 100%;
  background-position: center;
  background-size: cover;
}
@media (max-width: 767px) {
  .party-background-div {
    background-position: left;
  }
}

.party-background-div p {
  margin-top: 14rem;
  color: white !important;
  text-shadow: 10px 10px 20px #1e1e1c, 0 30px 70px #161515;
  padding: 20px;
  margin-left: -50%;
  line-height: 1.9em; 
 
 
}
@media (max-width: 820px) {
  .party-background-div p {
    margin-top: 5rem;
    margin-left: 0;
    line-height: 1.5em;
  }
}
@media (max-width: 960px) {
  .subtitle1 {
    font-size: 30px;
  }
}
@media (max-width: 960px) {
  .subtitle2 {
    font-size: 18px;
  }
}

.wrap-all-you-need-for-party-title {
  margin: 2rem;
  margin-bottom: 1rem;
}

.happyBD-form * {
  color: white !important;
  text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
  padding: 1rem;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0;
  transition: 1s;
  animation: show 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5ms;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tizer-unicorn {
  position: absolute;
  bottom: 10%;
  width: 230px;
  height: auto;
  overflow: hidden;
  animation: updown 5s ease-in-out infinite;

  margin-bottom: 1%;
}
@media (max-width: 767px) {
  .tizer-unicorn {
    width: 230px;
    left: 3%;
    bottom: 20%;
  }
}

@keyframes updown {
  0% {
    transform: translateY(30px);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(30px);
  }
}

.rainbow-5:hover {
  background-image: linear-gradient(
    to right,
    #e7484f,
    #e7484f 16.65%,
    #f68b1d 16.65%,
    #f68b1d 33.3%,
    #fced00 33.3%,
    #fced00 49.95%,
    #009e4f 49.95%,
    #009e4f 66.6%,
    #00aac3 66.6%,
    #00aac3 83.25%,
    #732982 83.25%,
    #732982 100%,
    #e7484f 100%
  );
  animation: slidebg 3s linear infinite;
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.rainbow-5 {
  margin-left: -50%;
}
@media (max-width: 820px) {
  .rainbow-5 {
    margin-left: 0;
  }
}
</style>