import axios from "axios"
import apiEndPoints from "../../constans/apiEndPoints"

export default {
    namespaced: true,

    state: {
        PageData: [
            
        //     {
        //     _id:'1',
        //     pageName: 'About',
        //     imageSubtitle3: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFZVyXOkryNqU0WV1aQgachilyqa-8AsrN2A&usqp=CAU',
        //     experienceYears: '4 роки',
        //     ideasForParty: 'понад 100',
        //     descriptionAboutPartyTimes: 'Тут мала бути цифра з кількістю проведенних свят...',
        //     partyTimesAnswer: '😍',
        //     subtitle1: 'Хто я?',
        //     subtitle1Description: 'Я Марина',
        //     subtitle2: 'Як все починалося...',
        //     subtitle2Description: 'Так все починолося з того що я придумала',
        //     subtitle3: 'Крокуючи до успіху',
        //     subtitle3Description: 'Так я крокую до успіху'

        // }
    ],
    },

    mutations: {
        // updatePageData(state, Data) {
        //     const pageIndex = state.PageData.findIndex(
        //         (page) => page.pageName == Data.pageName
        //     )
        //     state.PageData[pageIndex] = Data

        // },

        updatePageData(state, Data){
            state.PageData = [...Data]
        }
    },

    actions: {

        loadData({
            commit
          }) {
            axios.get(apiEndPoints.page.read)
              .then((res) => res.data)
              .then((resData) => {
                console.log('load data resdata pages ok');
                if (resData) {
                  commit("updatePageData", resData)
                }
              })
          },


        // updatePageDataAction({
        //     commit
        //   }, updatingPage) {
        //     commit('updatePageData', updatingPage)
        //   },

        updatePageDataAction({
            dispatch
          }, pageData) {
            console.log('update start');
            axios
              .put(apiEndPoints.page.update, pageData)
              .then(
                //Якщо добре
                
                (res) => res.data,
                
              )

              .then((resData) => {
                console.log(`resdata update page  $$$ ${resData}`);
                if (resData) dispatch('loadData')
                else throw new Error("Fatch failed!");
                
              })
          },
    },

    getters: {
        getPageData: (state) => state.PageData,


        getPageByName: (state) => (pageName) =>{
          return state.PageData.find((item) => item.pageName == pageName)
        }
        

    }
}