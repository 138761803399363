import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import store from './store'
import router from "./router";
import App from './App.vue'
import axios from "axios"
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.use(Buefy)
Vue.use(VueLazyload)


axios.interceptors.request.use(function(config) {
  const isAuthenticated = store.getters["auth/isAuthenticated"]();

  if (isAuthenticated) {
    config.headers["Authorization"] =
      "Bearer " + store.getters["auth/getAccessToken"]();
  } else {
    config.headers["Authorization"] = null;
  }
  return config;
});

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      // Якщо сервер відповів «401»
      router.push({ path: "/" });
    }
    return Promise.reject(error);
  }
);

const loadimage = require('./assets/logo.png')
const errorimage = require('./assets/instagram.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1,
  lazyComponent: true,
   observer: true,

  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  }
})





new Vue({
  
  store,
  router,
  render: h => h(App),
}).$mount('#app')
