<template>
  <div>
    <div class="nav-bar">
      <navbar />
    </div>

    <welcometext />

    <tizer2 />

    <tizer1 />
    <tizer3 />

    <div class="reviews">
   

      <reviews />
    </div>

    <br />
    <my-footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

import Tizer1 from "@/components/Tizers/Tizer1";
import Tizer2 from "@/components/Tizers/Tizer2";
import Tizer3 from "@/components/Tizers/Tizer3";
import Welcometext from "@/components/Welcometext";

import Reviews from "@/components/Reviews";
import MyFooter from "@/components/MyFooter";
import { mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    Navbar,
    Welcometext,

    Tizer1,
    Tizer2,
    Tizer3,
    Reviews,
    MyFooter,
  },

  methods: {
    ...mapActions("servises", ["loadData"]),

    jumpToServisPage() {
      this.$router.push({
        name: "ServisPage",
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="css" scoped>


.reviews {
  padding: 30px;
  position: relative;
  overflow: hidden;
  color: white;

  background-repeat: no-repeat;
  margin: auto;
  /* background-position-y: -380px; */

  background: #663785;
}
.horizontalLine {
  border-top: 6px dotted #ffffff;
  width: 3000px;
  left: -321px;
  top: 0;
  position: absolute;
}


.nav-bar {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}
</style>