export default{
    inserted: el =>{
        function loadImage(){
            el.src = el.dataset.src
        }
       

        function callback(entries, observer){
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadImage()
                   
                    console.log('show image');
                    observer.unobserve(el)
                }            
            })

        }

        function createIntersectionObserver(){
            const options = {
                root: null,
                rootMargin: '0px',
            }

            const observer = new IntersectionObserver(callback, options)
            observer.observe(el)
            
        }

        if (!window['IntersectionObserver']) {
            loadImage()
           
        } else {
            

            createIntersectionObserver()
        }
        
    }
}