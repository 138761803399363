<template>
  <div >
    <navbar />

    <b-table
    
      :data="getOrderList.slice().reverse()"
      :columns="columns"
      
      :bordered="true"
    
    >
    </b-table>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "@/components/Navbar";

export default {
  name: "OrderList",
  components: {
    Navbar,
  },

  data() {
    return {
      data: [],
      stickyHeaders: true,
    };
  },

  computed: {
    ...mapGetters("orders", ["getOrderList"]),
    columns() {
      return [
        // {
        //   field: "_id",
        //   label: "ID",
        //   width: "80",
        //   searchable: true,
        //   headerClass: "is-sticky-column-one",
        //   cellClass: "is-sticky-column-one",
        // },
        {
          field: "OrderedServisName",
          label: "Назва послуги",
          searchable: true,
          headerClass: "is-sticky-column-tree",
          cellClass: "is-sticky-column-tree",
        },
        {
          field: "userName",
          label: "Замовник",
          searchable: true,
        },
        {
          field: "userTel",
          label: "Телефон",
          searchable: true,
        },
       
         {
          field: "partyArea",
          label: "Місце проведення",
          searchable: true,
        },
        {
          field: "childrenCount",
          label: "Кількість дітей",
          searchable: true,
        },
        {
          field: "hoursPlaying",
          label: "Тривалість проведення",
          searchable: true,
        },
        {
          field: "userCommentsToOrder",
          label: "Коментарі до замовлення",
          searchable: true,
        },
        {
          field: "partyTime",
          label: "Плануєм проведення:",
          class: "is-success",
          searchable: true,
          sticky: true,
          headerClass: "is-sticky-column-two",
          cellClass: "is-sticky-column-two",
        },
        
        {
          field: "DateOfOrder",
          label: "Замовлення оформленно:",
          class: "is-success",
          searchable: true,
          sticky: true,
          headerClass: "is-sticky-column-one",
          cellClass: "is-sticky-column-one",
        },
         {
          field: "userEmail",
          label: "Емейл",
          searchable: true,
        },
        // {
        //   field: "servisId",
        //   label: "Id послуги",
        //   searchable: true,
        // },

      ];
    },
  },
  methods: {
    ...mapActions('orders', ['loadData'])
  },
  mounted () {
    this.loadData();
  },
};
</script>

<style  >
.is-sticky-column-one {
    background: #F6C6EA !important;
    color: rgb(0, 0, 0) !important;
    font-weight: bold;
    letter-spacing: 1px; 
    text-align: center;
}
.is-sticky-column-two {
    background: #CDF0EA !important;
    color: rgb(5, 5, 5) !important;
}
.is-sticky-column-tree {
    background: #FAF4B7!important;
    color: rgb(0, 0, 0) !important;

}


</style>