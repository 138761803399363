<template>
  <div>
    <hr />
    <div class="wrap">
      <div class="subtitle-quick-order-form">
        Заповніть коротку форму, і я неодмінно зв'яжуся з Вами! <br />
      </div>
      <div class="orderForm">
        <b-field label="* Ім'я:">
          <b-input
            placeholder="Як Вас звати?"
            required
            v-model="userName"
          ></b-input>
        </b-field>

        <b-field label="* Телефон:">
          <b-input
            required
            v-model="userTel"
            type="number"
            name="phone"
            placeholder="+380994380991"
            title="введіть номер"
            minlength="10"
            maxlength="13"
          >
          </b-input>
        </b-field>
        <b-field label="* Що цікавить?">
          <b-select
            required
            v-model="pickedServisId"
            placeholder="Оберіть послугу"
          >
            <option
              v-for="servis in getServisListData"
              :value="servis._id"
              :key="servis._id"
            >
              {{ servis.title }}
            </option>
          </b-select>
        </b-field>

        <b-button
          class="showQuickOrderButton pulseQuickOrderButton"
          v-if="pickedServisId && userName && userTel"
          @click="MakeOrder() ,alertCustom()"
          type="is-success"
          >Замовляю
        </b-button>
        <b-button
          disabled
          v-if="!pickedServisId || !userName || !userTel"
          type="is-light"
        >
          Замовляю
        </b-button>
      </div>
      <div class="manual-subtitle">
        А якщо лінь заповнювати, тоді телефонуйте
        <a href="tel: +380994380991"> (099)438 09 91 </a> <br />
        Або тисніть на значок вайберу
        <a title="Viber" href="viber://chat?number=+380994380991">
          <img src="@/assets/viber_logo.png" alt="viber" />
        </a>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "QuickOrderForm",
  computed: {
    ...mapGetters("servises", ["getServisById", "getServisListData"]),

    servisName() {
      let servis = this.getServisById(this.pickedServisId);
      return servis.title;
    },

    isCustomDuration() {
      let servis = this.getServisById(this.servisId);
      return servis.isCustomDuration;
    },
  },

  data() {
    return {
      userName: null,
      userTel: null,
      pickedServisId: null,
      DateOfOrder: new Date().toLocaleString(),
    };
  },

  methods: {
    ...mapActions("orders", ["addOrder"]),

    MakeOrder() {
      this.addOrder({
        userName: this.userName,
        userTel: this.userTel,
        servisId: this.pickedServisId,
        OrderedServisName: this.servisName,
        DateOfOrder: this.DateOfOrder,
      });
      console.log(this.getDefaultServisId);
    },
    alertCustom() {
      this.$buefy.dialog.alert({
        title: "Замовлення зроблено",
        message: "Дякую за замовлення, скоро Вам зателефоную  <b>Гарного дня!</b>!",
        confirmText: "Окей",
      });
    },
  },
};
</script>

<style lang="css" scoped>
@media only screen and (min-device-width: 1024px) {
  .orderForm {
    margin: auto;
    text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
    max-width: 300px;
    padding: 1rem;
    /* white-space: nowrap;
    text-decoration: none; */

    /* background-size: 100% ; */
    background-image: linear-gradient(90deg, #ecf5f6b1, #f7fefec4);
    border-radius: 30px;
  }

  .manual-subtitle {
    font-size: x-large;
    margin-top: 1rem;
  }
  .manual-subtitle a {
    color: #ffffff;
    text-decoration: underline;
  }

  .subtitle-quick-order-form {
    margin-top: 1rem;
    font-size: 30px;
    font-weight: bolder;
    padding: 2rem;
  }

  .showQuickOrderButton {
    opacity: 1;
    transition: 2s;
    animation: show 2s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5ms;
    animation: pulse 3s;
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(143, 247, 139, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
    50% {
      -moz-box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
    }
    100% {
      -moz-box-shadow: 0 0 0 rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
  }
}

@media only screen and (max-device-width: 1023px) and (min-device-width: 768px) {
  .orderForm {
    margin: auto;
    text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
    max-width: 400px;
    padding: 1rem;
    background-image: linear-gradient(90deg, #ecf5f6b1, #f7fefec4);
    border-radius: 30px;
  }

  .manual-subtitle {
    font-size: 20px;
    margin-top: 1rem;
  }
  .manual-subtitle a {
    color: #ffffff;
    text-decoration: underline;
  }

  .subtitle-quick-order-form {
    margin-top: 1rem;
    font-size: 30px;
    font-weight: bolder;
    padding: 2rem;
  }

  .showQuickOrderButton {
    opacity: 1;
    transition: 2s;
    animation: show 2s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5ms;
    animation: pulse 3s;
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(143, 247, 139, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
    50% {
      -moz-box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
    }
    100% {
      -moz-box-shadow: 0 0 0 rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
  }
}

@media only screen and (max-device-width: 767px) and (min-device-width: 375px) {
  .orderForm {
    margin: auto;
    text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
    max-width: 400px;
    padding: 1rem;
    background-image: linear-gradient(90deg, #ecf5f6b1, #f7fefec4);
    border-radius: 30px;
  }

  .manual-subtitle {
    font-size: 18px;
    margin-top: 1rem;
  }
  .manual-subtitle a {
    color: #ffffff;
    text-decoration: underline;
  }

  .subtitle-quick-order-form {
    margin-top: 1rem;
    font-size: 25px;
    font-weight: bolder;
    padding: 2rem;
  }

  .showQuickOrderButton {
    opacity: 1;
    transition: 2s;
    animation: show 2s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5ms;
    animation: pulse 3s;
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(143, 247, 139, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
    50% {
      -moz-box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
    }
    100% {
      -moz-box-shadow: 0 0 0 rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
  }
}
@media only screen and (max-device-width: 374px) and (min-device-width: 300px) {
  .orderForm {
    margin: auto;
    text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
    max-width: 400px;
    padding: 1rem;
    background-image: linear-gradient(90deg, #ecf5f6b1, #f7fefec4);
    border-radius: 30px;
  }

  .manual-subtitle {
    font-size: 17px;
    margin-top: 1rem;
  }
  .manual-subtitle a {
    color: #ffffff;
    text-decoration: underline;
  }

  .subtitle-quick-order-form {
    margin-top: 1rem;
    font-size: 20px;
    font-weight: bolder;
    padding: 2rem;
  }

  .showQuickOrderButton {
    opacity: 1;
    transition: 2s;
    animation: show 2s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5ms;
    animation: pulse 3s;
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(143, 247, 139, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
    50% {
      -moz-box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 15px rgba(143, 247, 139, 0.5);
    }
    100% {
      -moz-box-shadow: 0 0 0 rgba(143, 247, 139, 0.5);
      box-shadow: 0 0 0 0 rgba(143, 247, 139, 0.5);
    }
  }
}
</style>