// import { v4 as uuidv4 } from 'uuid';
import axios from "axios"
import apiEndPoints from "../../constans/apiEndPoints"

export default {
    namespaced: true,

    state: {
        NewsListData: [
            // {
            //     _id: 1,
            //     title: 'Title1',
            //     text: 'Text news1',
            //     img: require('@/assets/logo.png'),
            //     DateOfNews: Date().toLocaleString()
            // },
            // {
            //     _id: 2,
            //     title: 'Title2',
            //     text: 'Text news2',
            //     img: require('@/assets/viber_logo.png'),
            //     DateOfNews: Date().toLocaleString()
            // },
            // {
            //     _id: 3,
            //     title: 'Title3',
            //     text: 'Text news3 ',
            //     img: require('@/assets/instagram.png'),
            //     DateOfNews: Date().toLocaleString()
            // }
        ]
    },

    mutations: {

        setNewsListData(state, data) {
            state.NewsListData = [...data]
          },

        // addToNewsListData(state, data) {
        //     state.NewsListData.unshift({...data})
        // },

        // updateNews(state, NewsData){
        //     const newsIndex = state.NewsListData.findIndex(
        //         (news) => news._id == NewsData._id)
        //     state.NewsListData[newsIndex] = NewsData
        // },

        // deleteNews(state, NewsData){
        //     const RestOfNewsList = state.NewsListData.filter(
        //         (news) => news._id !== NewsData._id)
        //         state.NewsListData = RestOfNewsList
            
        // }
    },

    actions: {
       async loadData({
            commit
          }) {
          await  axios.get(apiEndPoints.news.read)
              .then((res) => res.data)
              .then((resData) => {
                console.log('load data news');
                if (resData) {
                  commit("setNewsListData", resData)
                }
              })
          },

          addNews({
            dispatch
         },newsData) {
      
           axios
             .post(apiEndPoints.news.add,newsData)
             .then(
               //Якщо добре
               (res) => res.data,
      
             )
      
             .then((resData) => {
              console.log('ok');
               if (resData) dispatch('loadData')
               else throw new Error("Fatch failed!");
             })
             .catch((err) => { //Якщо погано
               console.log(` tut eror:  @@@@ ${err}`);
             })
      
         },

         updateNewsAction({
            dispatch
          }, newsData) {
            
            axios
              .put(apiEndPoints.news.update, newsData)
              .then(
                //Якщо добре
                (res) => res.data,
              )
              .then((resData) => {
                console.log(`resdata  $$$ ${resData}`);
                if (resData) dispatch('loadData')
                else throw new Error("Fatch failed!");
              })
          },
          deleteNewsAction({ dispatch }, _id) {
      
            console.log("id");
            console.log(_id);
            // axios.delete(apiEndpoints.products.delete,{params:{id}})    //через params, тоді на сервері берем з req.query.id
            axios.delete(apiEndPoints.news.delete, { data: { _id } }) //через data, тоді на сервері берем з req.body.id        
              .then(                             //Якщо добре
                res => res.data
              )
              .then((resData) => {
                if(resData.success)   dispatch ('loadData')
                else throw new Error('Fatch failed!')
              })
              
          },

        // addNews({
        //     commit
        // }, newsData){
        //     commit('addToNewsListData',newsData )

        // },

        // updateNewsAction({
        //     commit
        // }, news){
        //     commit('updateNews', news)
        // },

        // deleteNewsAction({
        //     commit
        // }, news){
        //     commit('deleteNews',news)
        // }
    },

    getters: {
        getNewsListData: (state) => state.NewsListData,

        getNewsById: (state) => (id) =>{
            return state.NewsListData.find((item) => item._id == id)
        }

    }
}