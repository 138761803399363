<template>
  <div class="wrap">
      <!-- <div v-if="!isCelebrationsButtonClicked" class="horizontalLine"></div> -->
    <!-- <span v-if="!isCelebrationsButtonClicked" class="verticalLine"></span> -->

  
    <div
      :style="celebrations"
      class="celebrations-background-div has-text-centered"
    >
      <p class="title is-2 title-screen-les-768">
       Яскраве свято <br />
        <br />
        "під ключ"
      </p>
      <p class="subtitle is-4">Відриття закладів, тематичні вечірки..!</p>
    
     
        <b-button v-if="!isCelebrationsButtonClicked" class="center-button"  type="is-success is-large" @click="ClickOrderButton()">
           Влаштувати
        </b-button>

     <b-button
        v-if="!isCelebrationsButtonClicked"
        type="is-success is-large button-for-mobiles-devices "
        @click="ClickOrderButton()"
      >
        Влаштувати
       
      </b-button>

 <figure
        class="tizer-unicorn"
        v-if="!isCelebrationsButtonClicked"
      >
        <img src="@/assets/pngs/14.png" />
      </figure>
      <div
        class="wrap-all-you-need-for-party-title celebrations-form"
        v-if="isCelebrationsButtonClicked"
        v-on-clickaway="ClickOrderButton"
      >
        <quick-order-form />
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import QuickOrderForm from "@/components/QuickOrderForm";

export default {
  name: "Tizer3",
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    QuickOrderForm,
  },
  data() {
    return {
      celebrations: {
        backgroundImage: `url(${require("@/assets/partyTurnkey.png")})`,
      },
      isCelebrationsButtonClicked: false,
    };
  },
  methods: {
    ClickOrderButton() {
      this.isCelebrationsButtonClicked = !this.isCelebrationsButtonClicked;
    },
  },
};
</script>

<style lang="css" scoped>

  .wrap {
  overflow: hidden;
  position: relative;
}
.verticalLine {
  border-left: 6px dotted #f4efdb;
  height: 100%;
  position: absolute;
  left: 50%;
  
}
@media (max-width: 820px) {
  .verticalLine{
    display: none

  }
}

.horizontalLine {
  border-top: 6px dotted #f4efdb;
  width: 100%;
  position: absolute;
}
.celebrations-background-div {
  width: 100%;
  min-height: 900px;
  background-size: 130%;
  background-repeat: no-repeat;
  padding: 1rem;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: center;
}
@media (max-width: 767px) {
  .celebrations-background-div{
    background-position: right;
  }
}

.celebrations-background-div p {
  margin-top: 16rem;
  color: white !important;
  text-shadow: 10px 10px 20px #1e1e1c, 0 30px 70px #161515;
  padding: 20px;
  margin-left: 50%;
}
@media (max-width: 820px) {
  .celebrations-background-div p{
    margin-top: 9rem;
    margin-left: 0%;
  }
}
.button-for-mobiles-devices{
  display:none;
}
@media (max-width: 820px) {
  .button-for-mobiles-devices{
    display: inline;
    margin-left: 0;

  }
}
.center-button {
    position: absolute;
    top: 65%;
    left: 75%;
    transform: translate(-50%, -50%);
    
}
 .center-button:hover {
    background-image: linear-gradient(
      to right,
      #e7484f,
      #e7484f 16.65%,
      #f68b1d 16.65%,
      #f68b1d 33.3%,
      #fced00 33.3%,
      #fced00 49.95%,
      #009e4f 49.95%,
      #009e4f 66.6%,
      #00aac3 66.6%,
      #00aac3 83.25%,
      #732982 83.25%,
      #732982 100%,
      #e7484f 100%
    );
    animation: slidebg 3s linear infinite;
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

 

@media (max-width: 820px) {
  .center-button{
    display: none

  }
}


.wrap-all-you-need-for-party-title {
  margin: 2rem;
  margin-bottom: 1rem;
}

.celebrations-form * {
  color: white !important;
  text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
  padding: 1rem;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0;
  transition: 1s;
  animation: show 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5ms;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tizer-unicorn {
   display:none
  }
   @media (max-width: 767px) {
  .tizer-unicorn{
    display:block;
     position: absolute;
    
    width: 200px;
    height: auto;
    overflow: hidden;
    animation: updown 5s ease-in-out infinite;
 left: 25%;
    bottom: 1%;
    

  }
}
  @keyframes updown {
    0% {
      transform: translateY(30px);
    }

    50% {
      transform: translateY(-30px);
    }

    100% {
      transform: translateY(30px);
    }
  }

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {


.is-success{
  background-color: rgb(249, 217, 32);
  border-radius:18px;
  color: #1e1e1c;
}



}





/* @media only screen and (max-device-width: 767px) and (min-device-width: 320px) {
  .wrap-all-you-need-for-party-title {
    margin: 2rem;
    margin-bottom: 1rem;
    margin-left: 0px;
    margin-right: 0px;
  }
  .title-screen-les-768 {
    font-size: 40px;
  }
  
} */
</style>