<template>
  <div>
    <navbar />

    <div
      v-for="reviews in getReviewsListData"
      :key="reviews._id"
      class="box has-text-centered"
    >
      <article class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <img :src="reviews.img" alt="Image" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <strong>
              {{ reviews.reviewerName }}
            </strong>
            <p>{{ reviews.textReview }}</p>
            <br />
            <b-button @click="onEdit(reviews._id)" type="is-primary">
              Редагувати
            </b-button>
            <b-button @click="confirmCustomDelete(reviews)" type="is-danger ">
              Видалити відгук
            </b-button>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EditReviewsPage",

  components: {
    Navbar,
  },

  computed: {
    ...mapGetters("reviews", ["getReviewsListData"]),
  },

  methods: {
    ...mapActions("reviews", ["deleteReviewAction", "loadData"]),

    onEdit(_id) {
      this.$router.push({
        name: "UpdateReviewsForm",
        params: { reviews_id: _id },
      });
    },

    confirmCustomDelete(review) {
      this.$buefy.dialog.confirm({
        title: "Видалення відгуку",
        message:
          "Ти точно хочеш <b>видалити</b> цей відгук? Цю дію потім неможливо буде відмінити! ",
        confirmText: "Видалити",
        cancelText: "Не треба видаляти",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$buefy.toast.open("Відгук видалено!") &&
          this.deleteReviewAction(review),
      });
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style lang="css" scoped>
.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}

@media only screen and (max-device-width: 768px) {
  .column {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.card {
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  margin: 2rem;
  text-align: center;
  box-shadow: 0 0 10px rgba(150, 126, 126, 0.5);
  border-radius: 10px;
  width: 250px;
  padding: 1.5rem;
  margin: 1rem;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card button {
  margin: 10px;
}

.box {
  margin: 1rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.Date-stamp {
  text-decoration: underline;
  color: rgb(93, 175, 247);
}
.box button {
  margin: 10px;
}
</style>