<template>
  <div class="wrapperDiv">
     <div class="nav-bar">
     <navbar />
   </div>
    <section class="hero is-large  has-text-centered parallax " :style="mainImage">
      <div class="heroiwrap">
        <p class="title herotext">Новини</p>
        <p class="subtitle has-text-centered herotext">Шоу тайм</p>
      </div>
    </section>
  
    <news-list />
  
  </div>
</template>
<script>
</script>


<script>
import Navbar from "@/components/Navbar";
import NewsList from "@/components/NewsList";
import { mapActions} from "vuex"

export default {
  name: "NewsPage",
  components: {
    Navbar,
    NewsList,
  },
  data() {
    return {
       mainImage: {
        backgroundImage: `linear-gradient(90deg, #2223245f, #17181848), url(${require("@/assets/news.png")})`,
      },
    }
  },
   methods: {
   ...mapActions("news",[ 'loadData']),
  },

   mounted() {
    this.loadData(this)
  }

 
};
</script>

<style lang="css" scoped>
.nav-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  
}

.parallax {
  height: 100vh;
  min-height: 650px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
}

.heroiwrap {
 

  margin-top: 30%;
}
.heroiwrap {
 

  margin-top: 30%;
} 
@media only screen and (max-device-width: 820px) {
  .parallax {
    min-height: 590px;
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .heroiwrap {
 

  margin-top:60vh
}
  
}
.herotext {
  color: white !important;
   
  text-shadow: 10px 3px 20px #ffffff, 0 30px 70px #ffffff;
}
.title{
  font-size:50px;
}
.subtitle{
  font-size:30px
}
.wrapperDiv{
  background-color: rgb(248, 239, 247);
}

</style>