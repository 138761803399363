<template>
  <div>
    <navbar />
    <div class="mainDiv">
      <h2 class="title has-text-centered">{{ AddOrEditTitle }}</h2>

      <b-field label="Автор">
        <b-input v-model="reviewerName"></b-input>
      </b-field>

      <b-field label="Текст відгуку">
        <b-input
          placeholder="Наприклад: 
              Хороша кампанія"
          v-model="textReview"
          type="textarea"
        ></b-input>
      </b-field>

     
      <div>
        <b-field class="file is-warning" :class="{ 'has-name': !!file }">
          <b-upload v-model="file" class="file-label" @input="loadImage">
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Додати фото </span>
            </span>
            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-upload>
        </b-field>
        <div v-if="img">
          <img :src="img" alt="" width="100" />
        </div>
      </div>
      <br>

      <b-button @click="onAdd();" type="is-success" outlined>{{
        buttonTitle
      }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "@/components/Navbar";
export default {
  name: "AddReviewsForm",
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters("reviews", ["getReviewsById"]),
    reviewsId() {
      if (this.$route.params.reviews_id === "new") return null;
      else return this.$route.params.reviews_id;
    },

    buttonTitle() {
      if (this.reviewsId) return "Зберегти зміни";
      else return " Додати відгук";
    },
    AddOrEditTitle() {
      if (this.reviewsId) return "Редагувати новину";
      else return "Додати  відгук";
    },
  },
  data() {
    return {
      reviewerName: null,
      img: null,
      textReview: null,
      file: null
    };
  },

  methods: {
    ...mapActions("reviews", ["addReview", "updateReviewAction", "loadData"]),

    readFile(file) {
      // return new Promise((resolve, reject) => {
      //   let myReader = new FileReader();
      //   myReader.onloadend = function (e) {
      //     resolve(myReader.result);
      //     reject(console.log(`err is ${e}`));
      //   };
      //   myReader.readAsDataURL(file);
      // });
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(console.log(` error ${error}`));
      });
    },
     async loadImage(file) {
      this.img = await this.readFile(file);
    },

    onAdd() {
      if (!this.reviewsId) {
        this.addReview({
          reviewerName: this.reviewerName,
          img: this.img,
          textReview: this.textReview,
          
        });
      } else {
        this.updateReviewAction({
          _id: this.reviewsId,
          reviewerName: this.reviewerName,
          img: this.img,
          textReview: this.textReview,
      
        });
      }
    },
    jumpToHomePage() {
      this.$router.push({
        name: "Home",
      });
    },
  },

  mounted() {
    console.log(this.reviewsId);
    if (this.reviewsId) {
      var reviews = this.getReviewsById(this.reviewsId);
      this.reviewerName = reviews.reviewerName;
      this.img = reviews.img;
      this.textReview = reviews.textReview;
    }

    
  },
};
</script>

<style lang="css" scoped>
.mainDiv {
  max-width: 500px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: auto;
}
</style>