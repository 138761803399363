import Vue from 'vue'
import Vuex from 'vuex'
import orders from "./modules/orders"
import news from "./modules/news"
import pages from "./modules/pages"
import servises from "./modules/servises"
import reviews from "./modules/reviews"
import auth from "./modules/auth"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  

  },

  mutations: {

  },

  actions: {
    
  },

  getters: {


  },


  modules: {
    orders,
    news,
    pages,
    servises,
    reviews,
    auth
  },
})

export default store