<template>
  <div>
    <div class="title has-text-centered"> Відгуки моїх дорогих клієнтів</div>
    <div v-if="!getReviewsListData">
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>

    </div>

    <b-carousel
      class="carousela"
      :autoplay="true"
      :pause-hover="true"
      :repeat="true"
      :interval="3000"
      :has-drag="true"
    >
      <b-carousel-item v-for="item in getReviewsListData" :key="item._id">
        <section>
          <div class="hero-body has-text-centered">
            <img class="is-rounded images" :src="item.img" />

            <h2 class="title">{{ item.reviewerName }}</h2>
            <div>
              {{ item.textReview }}
              
            </div>
          </div>
        </section>
      </b-carousel-item>
    </b-carousel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Reviews",
  
  data() {
            return {
                isLoading: true,
                isFullPage: true
            }
        },
  computed: {
    ...mapGetters("reviews", ["getReviewsListData"]),
  },
  methods: {
    ...mapActions('reviews', ["loadData"])
  },
   mounted() {
    this.loadData();
  },
};
</script>

<style lang="css" scoped>
@media only screen and (min-device-width: 1024px) {
  .carousela {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    width: 50%;
  }
  .images {
    margin-top: 1rem;
    border-radius: 100%;
    width: 200px;
    height: 200px;
  }
}

.images {
  margin-top: 1rem;
  border-radius: 100%;
  width: 200px;
  height: 200px;
}
.title{
  color: white;
      text-shadow: 10px 10px 20px #1e1e1c, 0 30px 70px #161515;

}
</style>