<template>
  <div>
    <div>

      <news-item
        v-for="item in getNewsListData.slice().reverse()"
        :key="item._id"
        :_id="item._id"
        :img="item.img"
        :title="item.title"
        :text="item.text"
        :DateOfNews="item.DateOfNews"
      />
    </div>
    
    <hr>
    
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NewsItem from "./NewsItem";

export default {
  name: "NewsList",
  components: {
    NewsItem,
  },



 computed: {
    ...mapGetters("news", ["getNewsListData"]),


  },

  data() {
            return {
                isLoading: false,
                isFullPage: true
            }
        },

  methods: {
   ...mapActions('news', ['loadData']),


        
  },

 async mounted() {

  await this.loadData(this) 
 

    

    // console.log(this.totalNews);
  },
};
</script>

<style lang="css" scoped>

</style>