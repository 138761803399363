<template >
  <div class="wrap">
    <navbar />
    <div>
      <div class="card">
        <div class="image">
          <img :src="img" alt="show time" />
        </div>

        <div class="card-content">
          <div class="media">
            <div class="media-left"></div>
            <div class="media-content">
              <span class="title is-3">{{ title }}</span>
              <br />
              <br />
              <p class="subtitle is-6" v-if="isPriceVisible">
                Ціна {{ price }}
              </p>
            </div>
          </div>

          <div class="content">
            <div class="bubble"></div>
            <div class="bubble2"></div>
            <div class="bubble3"></div>
            <div class="bubble4"></div>
            <div class="description">
              {{ description }}
            </div>
          </div>

          <b-button
            v-if="!isOrderButtonPressed"
            @click="pressOrderButton"
            type="is-success is-medium"
            class="btn-agree"
            ><a href="#top">Те що треба!</a></b-button
          >

          <b-button
            v-if="!isOrderButtonPressed"
            type="is-warning is-medium"
            @click="JumpToServisPage"
            class="btn-no"
            >Назад</b-button
          >
        </div>
      </div>
      <a name="top"></a>

      <!-- <div v-if="!servisId">
        Йой щось пішло не так, йдіть на Головну і спробуйте ще раз
      </div> -->
    </div>
    <div v-if="isOrderButtonPressed" v-on-clickaway="ClickOrderOrderButton">
      <orderForm />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import OrderForm from "@/components/OrderForm";
import { directive as onClickaway } from "vue-clickaway";
import { mapGetters, mapActions } from "vuex";
export default {
  directives: {
    onClickaway: onClickaway,
  },

  name: "CurrentServis",
  components: {
    Navbar,
    OrderForm,
  },
  data() {
    return {
      id: this.$route.params.servis_id,
      title: null,
      img: null,
      price: null,
      isPriceVisible: false,
      description: null,
      isOrderButtonPressed: false,
    };
  },
  computed: {
    ...mapGetters("servises", ["getServisById"]),
  },

  methods: {
    ...mapActions("servises", ["loadData"]),

    pressOrderButton() {
      this.isOrderButtonPressed = true;
    },
    JumpToServisPage() {
      this.$router.push({
        name: "ServisPage",
      });
    },
    CurrentServis() {
      let servis = this.getServisById(this.id);
      if (servis) {
        ({
          title: this.title,
          img: this.img,
          price: this.price,
          isPriceVisible: this.isPriceVisible,
          description: this.description,
        } = servis);
        console.log("ok");
        console.log(servis);
      } else {
        this.JumpToServisPage();
        console.log("to servis page");
      }
    },
    ClickOrderOrderButton() {
      this.isOrderButtonPressed = !this.isOrderButtonPressed;
    },
  },

  mounted() {
    this.loadData().then(this.CurrentServis());
    window.scrollTo(0,0)

    // // if (this.servisId) {

    //   console.log('CurrentServis');
    //   console.log(CurrentServis);

    // if (CurrentServis) {
    //   ({
    //     title: this.title,
    //     img: this.img,
    //     price: this.price,
    //     isPriceVisible: this.isPriceVisible,
    //     description: this.description,

    //   }  = CurrentServis);

    //   // console.log("CurrentServis ");
    //   // console.log(CurrentServis);
    // } else {
    //   // this.JumpToServisPage();
    //   console.log('no current servis');
    //   // console.log(this.servisId);
    // }
    // } else {

    // console.log("servisId ");
    // console.log(this.servisId);

    // }
  },
};
</script>

<style lang="css" scoped>
.wrap {
  background-color: #27183b;
  padding-bottom: 1rem;
 
}
.description {
  margin-right: 1rem;
  overflow: hidden;
}
.btn-agree {
  border-radius: 10px;

  box-shadow: 5px 10px 10px #4e4c4cc7;
}
.btn-no {
  border-radius: 10px;
  color: rgb(0, 0, 0) !important;
  box-shadow: 5px 10px 10px #4e4c4cc7;
}
.card {
    
    font-size: 18px;

}
 

@media only screen and (min-device-width: 1024px) {
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; 
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 90px;
    border-radius: 30px;
    box-shadow: 0 0 20px rgba(150, 126, 126, 1.5);
  }
  .image {
    border-radius: 30px;
    max-width: 600px;
    min-width: 400px;
    z-index: 2;
  }

  .card-content {
    min-width: 400px;
    max-width: 600px;
    margin-left: 100px;
 
  }
 

  .bubble {
    z-index: 1;
    width: 100px;
    height: 100px;
    transform: rotate(-75deg);
    background: linear-gradient(
      99.54deg,
      rgba(230, 227, 76, 0.4) -30.94%,
      rgba(197, 89, 214, 0.4) 52.16%,
      rgba(237, 203, 33, 0.4) 99.25%
    );
    position: absolute;
    border-radius: 100%;
    right: 4%;
    top: 7%;
  }
  .bubble2 {
    z-index: 1;
    width: 141px;
    height: 141px;
    transform: rotate(-75deg);
   background: linear-gradient(
      99.54deg,
      rgba(230, 227, 76, 0.4) -30.94%,
      rgba(197, 89, 214, 0.4) 52.16%,
      rgba(237, 203, 33, 0.4) 99.25%
    );
    position: absolute;
    border-radius: 100%;
    right: 50%;
    top: 58%;
  }

  .bubble3 {
    z-index: 1;
    width: 70px;
    height: 70px;
    transform: rotate(-75deg);
    background: linear-gradient(
      99.54deg,
      rgba(230, 227, 76, 0.4) -30.94%,
      rgba(197, 89, 214, 0.4) 52.16%,
      rgba(237, 203, 33, 0.4) 99.25%
    );
    position: absolute;
    border-radius: 100%;
    right: 10%;
    top: 80%;
  }
  .bubble4 {
    z-index: 1;
    width: 100px;
    height: 100px;
    transform: rotate(-75deg);
    background: linear-gradient(
      99.54deg,
      rgba(230, 227, 76, 0.4) -30.94%,
      rgba(197, 89, 214, 0.4) 52.16%,
      rgba(237, 203, 33, 0.4) 99.25%
    );
    position: absolute;
    border-radius: 100%;
    right: 91%;
    top: 50%;
  }
}

button {
  margin: 1rem;
  z-index: 1;
  opacity: 0.9;
  color: white !important;
}
button:hover {
  transform: scale(1.1);
}
a {
  color: white;
}

.orderForm {
  margin: 10%;
}
</style>