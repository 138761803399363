import axios from "axios"
import apiEndPoints from "../../constans/apiEndPoints"

export default {
  namespaced: true,

  state: {
    servisListData: [
     
    ],

  },

  // mutations: {
  //   addServisToList(state, data) {
  //     state.servisListData.push({
  //       _id: uuidv4(),
  //       ...data,
  //     })
  //   },
  mutations: {
    setServisList(state, data) {
      state.servisListData = [...data]
    },

    // setStateServisId(state, data) {
    //   state.stateServisId = [...data]
    // },



    // updateServisFromList(state, servisData) {
    //   const servisIndex = state.servisListData.findIndex(
    //     (servis) => servis._id == servisData._id
    //   )
    //   state.servisListData[servisIndex] = servisData

    // },

    // deleteServisFromList(state, ServisData) {
    //   const RestOfServisList = state.servisListData.filter(
    //     (servis) => servis._id !== ServisData._id
    //   )
    //   state.servisListData = RestOfServisList

    // },


  },

  actions: {
    loadData({
      commit
    }) {
      axios.get(apiEndPoints.servises.read)
        .then((res) => res.data)
        .then((resData) => {
          console.log('load data resdata ok');
          if (resData) {
            commit("setServisList", resData)
          }
        })
    },

    

    // addNewServis({
    //   commit
    // }, servisData) {
    //   commit('addServisToList', servisData)
    // },
    // addNewServis({
    //   dispacth
    // }, servisData) {

    //   axios.post(apiEndPoints.servises.add, servisData)
    //     .then((res) => res.data)
    //     .then((resData) => {
    //       console.log('ok resData here');
    //       if (resData) dispacth('loadData')
    //       else throw new Error("Fatch failed!")
    //     })
    //     .catch((err) => {
    //       console.log(`here is error: ${err}`);
    //     })
    // },

    addNewServis({
      dispatch
   },servisData) {

     axios
       .post(apiEndPoints.servises.add, servisData) 
       .then(
         //Якщо добре
         (res) => res.data

       )

       .then((resData) => {
         
        
         if (resData) dispatch('loadData')
         else throw new Error("Fatch failed!");
       })
       .catch((err) => { //Якщо погано
         console.log(` tut eror:  @@@@ ${err}`);
       })

   },

    // updateServis({
    //   commit
    // }, servis) {
    //   commit('updateServisFromList', servis)
    // },

    updateServis({
      dispatch
    }, servisData) {
      
      axios
        .put(apiEndPoints.servises.update, servisData)
        .then(
          //Якщо добре
          (res) => res.data,
        )
        .then((resData) => {
          console.log(`resdata  $$$ ${resData}`);
          if (resData) dispatch('loadData')
          else throw new Error("Fatch failed!");
        })
    },

    // deleteServisAction({
    //   commit
    // }, servisData) {
    //   commit('deleteServisFromList', servisData)
    // },

    deleteServisAction({ dispatch }, _id) {
      
      console.log("id");
      console.log(_id);
      // axios.delete(apiEndpoints.products.delete,{params:{id}})    //через params, тоді на сервері берем з req.query.id
      axios.delete(apiEndPoints.servises.delete, { data: { _id } }) //через data, тоді на сервері берем з req.body.id        
        .then(                             //Якщо добре
          res => res.data
        )
        .then((resData) => {
          if(resData.success)   dispatch ('loadData')
          else throw new Error('Fatch failed!')
        })
        
    },

  },

  getters: {
    getServisListData: (state) => state.servisListData,

    getServisById: 
     (state) => (id) => {
      return  state.servisListData.find((item) =>  item._id == id)
    },


    

    




  },
}