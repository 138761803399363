<template>
  <div class="mainWrap">
    <navbar class="nav-bar" />
    <a name="top"></a>

    <section
      class="hero is-large has-text-centered parallax"
      :style="mainImage"
    >
      <div class="heroiwrap">
        <div class="hero-title">
          <span class="title deco-title1">Наші послуги</span>

          <div class="subtitle-div subtitle-deco">
            <span class="subtitle has-text-centered">
              <strong class="sub-deco">
                Впевнена, тут знайдете саме те, що Вам до вподоби! 
              </strong>
            </span>
          </div>
        </div>

        <br />
      </div>
    </section>
    <div class="listwarpper">
      <div class="box">
        <div class="div-for-list-view" @click="switchToLinksView">
          <i v-if="!isLinksView" class="fa-solid fa-bars"></i>
          <i v-if="isLinksView" class="fa-brands fa-buromobelexperte"></i>
          <p>{{ tooltipLabel }}</p>
        </div>
      </div>

      <div v-if="!isLinksView" class="flex-cards">
        <div @scroll="sort()" v-for="servis in sortedGetServisListData" :key="servis._id">
          <div class="card">
            <div class="ribbon" v-if="servis.hasRibbon">
              <span
                v-bind:class="{
                  'ribbon-span': servis.ribbonStyle1,
                  'ribbon2-span': servis.ribbonStyle2,
                }"
                >{{ servis.textOnRibbon }}</span
              >
            </div>

            <br />

            <p class="title is-5 card-title">{{ servis.title }}</p>
            <br />
            <div class="card-conten">
              <div class="image is-5by4">
                <img :src="servis.img" alt="servis.title" />
              </div>
              <div class="content">
                <br />

                <!-- <p class="subtitle is-6" v-if="servis.isPriceVisible">ціна : {{ servis.price }} грн.</p>
              <p class="subtitle is-6" v-if="!servis.isPriceVisible">ціна в деталях</p> -->
                <b-button @click="onClick(servis._id)"> Детальніше </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLinksView">
        <div
          class="has-text-centered LinksViewWrap"
          v-for="servis in sortedGetServisListData"
          :key="servis.index"
        >
          <div @click="onClick(servis._id)" class="title is-5 LinksView">
            {{ servis.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

// import ServisList from "@/components/ServisList";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ServisPage",
  components: {
    // ServisList,
    Navbar,
  },

  computed: {
    ...mapGetters("servises", ["getServisListData"]),
    tooltipLabel() {
      if (this.isLinksView === false) return "показати списком";
      else return "показати картками";
    },

    sortedGetServisListData() {
      let sortedArray = this.getServisListData
     return sortedArray.sort((a, b) => a.rating - b.rating);
        
    },
  },
  data() {
    return {
      mainImage: {
        backgroundImage: `linear-gradient(90deg, #2223245f, #17181848), url(${require("@/assets/servis-page-main.png")})`,
      },
      isLinksView: false,
    };
  },

  methods: {
    ...mapActions("servises", ["loadData"]),

    onClick(servis_id) {
      ///
      this.$router.push({
        name: "CurrentServis",
        params: { servis_id }, ///
      });
    },

    switchToLinksView() {
      this.isLinksView = !this.isLinksView;
    },
  
  },

  mounted() {
    this.loadData(this);
    window.scrollTo(0, 0);
  },
};
</script>

<style  lang="css" scoped>
.heroiwrap {
  display: flex;
  justify-content: center;

  margin-top: 30%;
}

@media (max-width: 820px) {
  .hero-title {
    margin-top: 50%;
  }
}

.listwarpper {
   background-color: rgb(248, 239, 247);
}

.box {
  display: flex;
  box-shadow: none;
  color: #663785;
  margin: 0;
  background-color: rgb(248, 239, 247);
  justify-content: start;
}

.div-for-list-view {
  text-align: center;
  width: 100px;
  /* background-color: #f376b0; */

  /* min-height: 50px;
  max-width: 90px;
  padding: 10px; */
}
.div-for-list-view i {
  font-size: 50px;
}
.div-for-list-view p {
  font-size: 14px;
}
.div-for-list-view:hover {
  cursor: pointer;
}
@media (max-width: 820px) {
  .div-for-list-view p {
    font-size: 16px;
  }
}

/* .cards-wrapper{
  background:#e9c528f6;

 
  
} */
.flex-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.parallax {
  /* The image used */

  /* Set a specific height */
  min-height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-device-width: 767px) {
  .parallax {
    background-attachment: scroll;
  }
}

.card {
  padding: 1rem;
  background-color: #ffffff;
  margin: 2rem;
  text-align: center;
  box-shadow: 0 0 10px rgba(150, 126, 126, 0.457);
  border-radius: 10px;
  height: 414px;
  width: 250px;
  overflow: hidden;
}

.card button {
  height: 50px;
  width: 190px;
  margin: 17px;
  box-sizing: border-box;
  border: transparent;

  font-family: "Comfortaa";
  font-size: 19px;
  font-weight: bolder;

  letter-spacing: 0.1em;
  background-color: #663785;
  padding: 10px;
  border-radius: 30px;
  color: #ffffff;

  cursor: pointer;
  outline: none;
}

.LinksViewWrap {
  display: flex;
  justify-content: center;
}
.LinksView {
  padding: 9px;
  font-size: 25px;
  width: 450px;

  background-color: #fdfdfb;
  color: #1f5a71;
  border-radius: 30px;
  border: 0px solid #db7e97;

  margin: 10px;
}
.LinksView:hover {
  cursor: pointer;
  background-color: #663785;
  color:rgb(255, 255, 255);
}

/* card button {
 border-radius: 4px;
  background-color: #5ca1e1;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 22px;
  padding: 16px;
  width: 160px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 6px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
  

} */

.card button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.card button:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 10px;
  right: -20px;
  transition: 0.5s;
}

.card button:hover {
  padding-right: 24px;
  padding-left: 8px;
}

.card button:hover:after {
  opacity: 1;
  right: 10px;
}

.subtitle-deco {
  margin: 20px !important;

}

.hero {
  /* background-position: center; */
  /* background-size: cover; */
  /* position: absolute; */
  /* width: 100%; */
  /* top: 20px; */
  height: 100vh;
}

.sub-deco {
  color: white;
  text-shadow: 10px 3px 20px #ffffff, 0 30px 70px #ffffff;
  font-size: 26px;

  border-radius: 30px;
  padding: 10px;
  margin-top: 100px;
}

.nav-bar {
  position: sticky;
  top: 0;
  z-index: 300;
}

.deco-title1 {
  /* background-image: linear-gradient(90deg, #abe9ec, #7bb9f3, #bcece5); */
  font-size: 38px;
  padding: 10px;

  border-radius: 30px;
  color: #ffffff !important;
  text-shadow: 10px 3px 20px #ffffff, 0 30px 70px #ffffff;
}

.card-title {
  background: linear-gradient(-45deg, #f6f085, #ffffff, #f6e984, #f9ea65);

  margin-left: -100px;

  width: 400px;
  height: 40px;
  padding: 10px;
}

.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
i {
  font-family: fontawesome !important;
}
</style>