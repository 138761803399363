<template>
  <div>
    <div class="mainDiv">
      <h2>Редагувати інформацію на сторінці</h2>
      <div class="inputsFields">
        <b-field label="pageTitle">
          <b-input v-model="pageTitle"></b-input>
        </b-field>
        <b-field label="MainDescriptionPage">
          <b-input v-model="MainDescriptionPage"></b-input>
        </b-field>

        <b-field label="subtitle1">
          <b-input v-model="subtitle1"></b-input>
        </b-field>
        <b-field label="subtitle1Description">
          <b-input v-model="subtitle1Description"></b-input>
        </b-field>
        <b-field label="subtitle2">
          <b-input v-model="subtitle2"></b-input>
        </b-field>
        <b-field label="subtitle2Description">
          <b-input v-model="subtitle2Description"></b-input>
        </b-field>
        <b-field label="subtitle3">
          <b-input v-model="subtitle3"></b-input>
        </b-field>
        <b-field label="subtitle3Description">
          <b-input v-model="subtitle3Description"></b-input>
        </b-field>

        <b-field label="subtitle4">
          <b-input v-model="subtitle4"></b-input>
        </b-field>
        <b-field label="subtitle4Description">
          <b-input v-model="subtitle4Description"></b-input>
        </b-field>

        <!-- <b-field label="imageCard3">
          <b-input v-model="imageCard3"></b-input>
        </b-field>
         -->

        <div>
          <b-field class="file is-warning" :class="{ 'has-name': !!file }">
            <b-upload v-model="file" class="file-label" @input="loadImage">
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Додати фото товару</span>
              </span>
              <span class="file-name" v-if="file">
                {{ file.name }}
              </span>
            </b-upload>
          </b-field>
          <div v-if="imageCard3">
            <img :src="imageCard3" alt="" width="100" />
          </div>
        </div>

        <b-field label="card1Title">
          <b-input v-model="card1Title"></b-input>
        </b-field>
        <b-field label="card1Description">
          <b-input v-model="card1Description"></b-input>
        </b-field>
        <b-field label="card2Title">
          <b-input v-model="card2Title"></b-input>
        </b-field>
        <b-field label="card2Description">
          <b-input v-model="card2Description"></b-input>
        </b-field>
        <b-field label="card3Title">
          <b-input v-model="card3Title"></b-input>
        </b-field>

        <b-field label="card3Description">
          <b-input v-model="card3Description"></b-input>
        </b-field>

        <b-button v-if="isAuthenticated()" @click="onChangePageData()" type="is-success"
          >Update page</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EditPageForm",

  computed: {
    ...mapGetters("pages", ["getPageData", "getPageByName"]),
    ...mapGetters("auth", [ "isAuthenticated"]),
    findPageName() {
      return this.$route.name.toLowerCase();
    },
  },

  data() {
    return {
      pageTitle: "Про Нас",
      pageName: "about",
      MainDescriptionPage: "Про команду та місію Шоу Тайма",
      subtitle1: "subtitle1",
      subtitle1Description: "subtitle1Description",
      subtitle2: "subtitle2",
      subtitle2Description: "subtitle2Description",
      subtitle3: "subtitle3",
      subtitle3Description: "subtitle3Description",
      subtitle4: "subtitle4",
      subtitle4Description: "subtitle4Description",
      card1Title: "card1Title",
      card1Description: "card1Description",
      card2Title: "card2Title",
      card2Description: "card2Description",
      card3Title: "card3Title",
      card3Description: "card3Description",
      imageCard3: null,
      file: null,
    };
  },

  methods: {
    ...mapActions("pages", ["updatePageDataAction"]),

    readFile(file) {
     
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(console.log(` error ${error}`));
      });
    },

    async loadImage(file) {
      this.imageCard3 = await this.readFile(file);
    },

    onChangePageData() {
      if (this.isAuthenticated()) {
        this.updatePageDataAction({
        pageTitle: this.pageTitle,
        MainDescriptionPage: this.MainDescriptionPage,
        pageName: this.findPageName.toLowerCase(),
        subtitle1: this.subtitle1,
        subtitle1Description: this.subtitle1Description,
        subtitle2: this.subtitle2,
        subtitle2Description: this.subtitle2Description,
        subtitle3: this.subtitle3,
        subtitle3Description: this.subtitle3Description,
        subtitle4: this.subtitle4,
        subtitle4Description: this.subtitle4Description,
        card1Title: this.card1Title,
        card1Description: this.card1Description,
        card2Title: this.card2Title,
        card2Description: this.card2Description,
        card3Title: this.card3Title,
        card3Description: this.card3Description,
        imageCard3: this.imageCard3,
      });

       this.$router.push({
        name: "ServisPage",
      });
      } else {
        console.log('not Authenticated');
        
      }
      
    },
  },

  mounted() {
    if (this.findPageName) {
      console.log(this.findPageName.toLowerCase());
      const page = this.getPageByName(this.findPageName);

      (this.MainDescriptionPage = page.MainDescriptionPage),
        (this.pageName = page.pageName);
      (this.subtitle1 = page.subtitle1),
        (this.subtitle1Description = page.subtitle1Description),
        (this.subtitle2 = page.subtitle2),
        (this.subtitle2Description = page.subtitle2Description),
        (this.subtitle3 = page.subtitle3);
      (this.subtitle3Description = page.subtitle3Description),
        (this.subtitle4 = page.subtitle4);
      (this.subtitle4Description = page.subtitle4Description),
        (this.card1Title = page.card1Title),
        (this.card1Description = page.card1Description);
      (this.card2Title = page.card2Title),
        (this.card2Description = page.card2Description),
        (this.card3Title = page.card3Title),
        (this.card3Description = page.card3Description),
        (this.imageCard3 = page.imageCard3);
    }
  },
};
</script>

<style lang="css" scoped>
</style>