<template>
  <div>
    <navbar />
    <div class="mainDiv">
      <h2 class="title is-3 has-text-centered">{{ AddOrEditTitle }}</h2>

      <b-field label="Назва послуги">
        <b-input v-model="title" maxlength="18"></b-input>
      </b-field>
      <b-field label="Ціна">
        <b-input v-model="price"></b-input>
      </b-field>
      <b-field label="Чи може користувач бачити ціну цьої послуги?">
        <b-checkbox v-model="isPriceVisible" type="is-success"></b-checkbox>
      </b-field>

      <div>
        <b-field class="file is-warning" :class="{ 'has-name': !!file }">
          <b-upload v-model="file" class="file-label" @input="loadImage">
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Додати фото товару</span>
            </span>
            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-upload>
        </b-field>
        <div v-if="img">
          <img :src="img" alt="" width="100" />
        </div>
      </div>

      <b-field label="Опис">
        <b-input
          v-model="description"
          maxlength="900"
          type="textarea"
        ></b-input>
      </b-field>
      <b-field label="Чи може користувач задавати тривалість годин послуги?">
        <b-checkbox v-model="isCustomDuration" type="is-success"></b-checkbox>
      </b-field>
      <b-field label="Додати стрічку на картку ?">
        <b-checkbox v-model="hasRibbon" type="is-success"></b-checkbox>
      </b-field>
      <div v-if="hasRibbon">
        <b-field  label="Що там напишимио?">
          <b-input
            v-model="textOnRibbon"
            maxlength="6"
            type="textarea"
          ></b-input>
        </b-field>
        <b-field v-if="!ribbonStyle2" label=" стиль стрічки 1 ?">
          <b-checkbox v-model="ribbonStyle1" type="is-success"></b-checkbox>
        </b-field>
        <b-field v-if="!ribbonStyle1" label="стиль стрічки 2?">
          <b-checkbox v-model="ribbonStyle2" type="is-success"></b-checkbox>
        </b-field>
      </div>
      <b-field label="Рейтинг  ">
      <b-input v-model="rating" :max="servisesCount" min="0" type="is-success"></b-input>
    </b-field>

      <b-button @click="onAdd(), jumpToServisPage() "  type="is-success" outlined>{{
        buttonTitle
      }}</b-button>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "@/components/Navbar";
export default {
  name: "AddServisForm",
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters("servises", ["getServisById", "getServisListData"]),
    servisId() {
      if (this.$route.params.servis_id === "new") return null;
      else return this.$route.params.servis_id;
    },

    buttonTitle() {
      if (this.servisId) return "Зберегти зміни";
      else return " Додати нову послугу";
    },
    AddOrEditTitle() {
      if (this.servisId) return "Редагувати сервіс";
      else return "Додати  сервіс";
    },
    servisesCount(){
      if (this.getServisListData) {
       
        return this.getServisListData.length
        
      } else{
        return console.log('no sevis list data');
      } 
    }
  },
  data() {
    return {
      title: null,
      img: null,
      price: null,
      isPriceVisible: null,
      description: null,
      isCustomDuration: null,
      file: null,
      hasRibbon: null,
      textOnRibbon: null,
      ribbonStyle1: null,
      ribbonStyle2: null,
      rating: null,
    };
  },

  methods: {
    ...mapActions("servises", ["addNewServis", "updateServis", "loadData"]),

    

    readFile(file) {
      // return new Promise((resolve, reject) => {
      //   let myReader = new FileReader();
      //   myReader.onloadend = function (e) {
      //     resolve(myReader.result);
      //     reject(console.log(`err is ${e}`));
      //   };
      //   myReader.readAsDataURL(file);
      // });
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(console.log(` error ${error}`));
      });
    },

    async loadImage(file) {
      this.img = await this.readFile(file);
    },

    onAdd() {
      if (!this.servisId) {
        this.addNewServis({
          title: this.title,
          img: this.img,
          price: this.price,
          isPriceVisible: this.isPriceVisible,
          description: this.description,
          isCustomDuration: this.isCustomDuration,
          hasRibbon: this.hasRibbon,
          ribbonStyle1: this.ribbonStyle1,
          ribbonStyle2: this.ribbonStyle2,
          textOnRibbon: this.textOnRibbon,
          rating: this.rating,
        });
      } else {
        this.updateServis({
          _id: this.servisId,
          title: this.title,
          img: this.img,
          price: this.price,
          isPriceVisible: this.isPriceVisible,
          description: this.description,
          isCustomDuration: this.isCustomDuration,
          hasRibbon: this.hasRibbon,
          ribbonStyle1: this.ribbonStyle1,
          ribbonStyle2: this.ribbonStyle2,
          textOnRibbon: this.textOnRibbon,
          rating: this.rating,
        });
      }
    },
    jumpToServisPage() {
      this.loadData();
      this.$router.push({
        name: "EditServisForm",
      });
    },
  },

   mounted() {
    this.loadData()
    console.log(this.servisId)
   console.log(this.servisesCount);
    if (this.servisId) {
      var servis = this.getServisById(this.servisId);
      (this.title = servis.title),
        (this.img = servis.img),
        (this.price = servis.price),
        (this.isPriceVisible = servis.isPriceVisible),
        (this.description = servis.description),
        (this.isCustomDuration = servis.isCustomDuration),
        (this.hasRibbon = servis.hasRibbon),
        (this.textOnRibbon = servis.textOnRibbon),
        (this.ribbonStyle1 = servis.ribbonStyle1),
        (this.ribbonStyle2 = servis.ribbonStyle2);
        (this.rating = servis.rating)
    }
  },
};
</script>

<style lang="css" scoped>
.mainDiv {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
</style>