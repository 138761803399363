<template>
  <div class="wrap">
    <span
      v-if="!isHappyBirthdayOrderFormButtonClicked"
      class="verticalLine"
    ></span>
    <!-- <div
      v-if="!isHappyBirthdayOrderFormButtonClicked"
      class="horizontalLine"
    ></div> -->

    <div
      v-if="!isHappyBirthdayOrderFormButtonClicked"
      class="wraperForAnimatedTabs has-text-centered"
    >
      <br />
      <p
        class="title is-2 title-for-AnimatedTabs"
        style="
          color: white;
          text-shadow: 10px 10px 20px #1e1e1c, 0 30px 70px #161515;
        "
      >
        Детальніше <br />
        <br />
        про наші послуги:
      </p>
      <br />
      <div class="moveTabsHover">
        <span @click="JumpToServisPage" class="colored1 colored subtitle is-2">
          <p>Анімація</p>
        </span>

        <span @click="JumpToServisPage" class="colored2 colored subtitle is-2">
          <p>Аквагрим</p>
        </span>
        <span @click="JumpToServisPage" class="colored3 colored subtitle is-2">
          <p>Квест</p>
        </span>

        <span @click="JumpToServisPage" class="colored4 colored subtitle is-2">
          <p>Майстер-Клас</p>
        </span>
        <span @click="JumpToServisPage" class="colored5 colored subtitle is-2">
          <p>Та Інше...</p>
        </span>
      </div>
    </div>
    <div class="party-background-div has-text-centered">
      <p class="title is-2 tittle-screen-device-les375">
        Швидке <br />
        <br />
        замовлення
      </p>
      <p class="subtitle is-4">в двох кроках від мрії ...</p>

      <b-button
        v-if="!isHappyBirthdayOrderFormButtonClicked"
        type="is-success is-large rainbow-5"
        @click="ClickHappyBirthdayOrderButton()"
      >
        Вперед!
        <!-- <a href="#to-quick-order-form">Святкувати</a> -->
      </b-button>

      <div
        class="wrap-all-you-need-for-party-title happyBD-form"
        v-if="isHappyBirthdayOrderFormButtonClicked"
        v-on-clickaway="ClickHappyBirthdayOrderButton"
      >
        <quick-order-form />
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import QuickOrderForm from "@/components/QuickOrderForm";

export default {
  name: "Tizer1",
  directives: {
    onClickaway: onClickaway,
  },

  components: {
    QuickOrderForm,
  },
  data() {
    return {
    
      isHappyBirthdayOrderFormButtonClicked: false,
    };
  },
  methods: {
    ClickHappyBirthdayOrderButton() {
      this.isHappyBirthdayOrderFormButtonClicked =
        !this.isHappyBirthdayOrderFormButtonClicked;
    },

    JumpToServisPage() {
      this.$router.push({
        name: "ServisPage",
      });
    },
  },
};
</script>

<style lang="css" scoped>
.wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 900px;
  background-color: #663785;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 1rem;
  height: 100%;
  background-position: center;
  background-size: cover;

   /* background-color: #2ea686; */
}
.verticalLine {
  border-left: 6px dotted #f4efdb;
  top: 0;
  height: 100%;
  position: absolute;
  left: 50%;
}

@media (max-width: 820px) {
  .verticalLine {
    display: none;
  }
}
.horizontalLine {
  border-top: 6px dotted #f4efdb;
  width: 100%;
  position: absolute;
  top: 0;
}

.party-background-div p {
  margin-top: 11rem;
  color: white !important;
  text-shadow: 10px 10px 20px #1e1e1c, 0 30px 70px #161515;
  padding: 20px;
  margin-left: -50%;
}
@media (max-width: 820px) {
  .party-background-div {
    display: none;
  }
}

.wrap-all-you-need-for-party-title {
  margin: 2rem;
  margin-bottom: 1rem;
}

.happyBD-form * {
  color: white !important;
  text-shadow: 10px 10px 20px #ffffff, 0 30px 70px #ffffff;
  padding: 1rem;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0;
  transition: 1s;
  animation: show 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5ms;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.is-success {
  background-color: rgb(248, 255, 38);
  color:#200929

}
.wraperForAnimatedTabs {
  top: 12rem;
  max-width: 500px;
  left: 60%;
  position: absolute;
  margin: auto;
}

@media (max-width: 820px) {
  .wraperForAnimatedTabs {
    left: 0;
    right: 0;
    top: 10rem;
  }
  .title-for-AnimatedTabs {
    line-height: 1.5rem;
    font-size: 35px;
  }
}

.moveTabsHover:hover .colored {
  transform: translateY(80px);
}

.colored1 {
  background-color: rgb(152, 37, 228);
  max-width: 300px;
  min-width: 200px;
  border-radius: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
  transform: rotate(-10deg);
  transition: 0.3s;
  box-shadow: 18px 0 16px rgba(0, 0, 0, 0.592);
  cursor: pointer;
}
.colored1 p {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.colored2 {
  background-color: rgb(250, 26, 130);
  max-width: 320px;
  min-width: 120px;
  border-radius: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  transform: rotate(10deg);
  transition: 0.5s;
  box-shadow: 18px 0 16px rgba(0, 0, 0, 0.592);
  cursor: pointer;
}

.colored2 p {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.colored3 {
  background-color: rgb(65, 216, 236);
  max-width: 200px;
  min-width: 100px;
  border-radius: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  transform: rotate(-10deg);
  transition: 0.7s;
  box-shadow: 18px 0 16px rgba(0, 0, 0, 0.592);
  cursor: pointer;
}
.colored3 p {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.colored4 {
  background-color: rgb(86, 206, 38);
  max-width: 400px;
  min-width: 300px;
  border-radius: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  transform: rotate(10deg);
  transition: 0.9s;
  box-shadow: 18px 0 16px rgba(0, 0, 0, 0.592);
  cursor: pointer;
}
.colored4 p {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.colored5 {
  background-color: rgb(240, 113, 233);
  max-width: 300px;
  min-width: 200px;
  border-radius: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 7rem;
  transform: rotate(-10deg);
  transition: 1.1s;
  box-shadow: 18px 0 16px rgba(0, 0, 0, 0.592);
  cursor: pointer;
}
.colored5 p {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.rainbow-5:hover {
  background-image: linear-gradient(
    to right,
    #e7484f,
    #e7484f 16.65%,
    #f68b1d 16.65%,
    #f68b1d 33.3%,
    #fced00 33.3%,
    #fced00 49.95%,
    #009e4f 49.95%,
    #009e4f 66.6%,
    #00aac3 66.6%,
    #00aac3 83.25%,
    #732982 83.25%,
    #732982 100%,
    #e7484f 100%
  );
  animation: slidebg 3s linear infinite;
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.rainbow-5 {
  margin-left: -50%;
}
</style>