<template>
    <div>
<footer class="footer">
  <div class="content has-text-centered">
    <p>
      <strong>Шоу Тайм</strong> майстерня дитячих свят <a href="https://www.instagram.com/show_time_uzh/" target="_blank">instagram</a>. 
      Розробка та створення сайтів <a href="https://www.instagram.com/oleg_efendi/?igshid=YmMyMTA2M2Y=">Oleh Cherniatiev</a>.
    </p>
  </div>
</footer>
    </div>
</template>

<script>
    export default {
        name: "MyFooter",
    }
</script>

<style lang="scss" scoped>

</style>